import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';

function Foot(props) {
  const {
    page,
    rowsPerPage,
    onPageChange,
    numberOfRows,
    onRowsPerPageChange,
  } = props;

  return (
    <TablePagination
      page={page}
      component="div"
      count={numberOfRows}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage="Registros por página:"
      rowsPerPageOptions={[10, 25, 50, 75, 100]}
      onPageChange={(e, page) => onPageChange(page)}
      nextIconButtonProps={{ 'aria-label': 'Próxima página' }}
      backIconButtonProps={{ 'aria-label': 'Página anterior' }}
      onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
      labelDisplayedRows={(ps) => `${ps.from} - ${ps.to} de ${ps.count}`}
    />
  );
}

Foot.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

export default Foot;
