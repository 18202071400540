import React from 'react';
import PropTypes from 'prop-types';
import { some, range } from '../../core/util';
import Button from './Button';

function getRange(currentPage, pages, displayedRange) {
  const fullRange = range(Math.min(pages, displayedRange));

  if (pages <= displayedRange) {
    return {
      center: fullRange,
    };
  }

  const block = Math.floor(currentPage / displayedRange);
  const maxBlock = Math.ceil(pages / displayedRange) - 1;
  const left = block * displayedRange;

  if (block === 0) {
    return {
      center: fullRange.map((i) => i + left),
      right: pages,
    };
  }
  if (block === maxBlock) {
    return {
      left: 1,
      center: fullRange.map((i) => i + left).filter((x) => x < pages),
    };
  }

  return {
    left: 1,
    center: fullRange.map((i) => i + left),
    right: pages,
  };
}

function Pagination(props) {
  const {
    style,
    onPageChange,
    displayedRange,
    pages: oldPages,
    ...others
  } = props;
  const [currentPage, setCurrentPage] = React.useState(0);
  const pages = Math.ceil(oldPages);

  if (pages < 0) {
    throw new Error('o número de páginas deve ser maior ou igual a 0 (zero).');
  }
  if (pages === 0) {
    return <div />;
  }

  const { left, center, right } = getRange(currentPage, pages, displayedRange);
  return (
    <div style={{ display: 'flex', ...style }} {...others}>
      <span style={{ margin: 'auto' }}>
        <Button
          onClick={(e) => {
            if (currentPage > 0) {
              setCurrentPage(currentPage - 1);
              onPageChange(e, currentPage - 1);
            }
          }}
        >
          {'<'}
        </Button>
        {some(left) && (
          <Button
            highlight={currentPage === 0}
            onClick={(e) => {
              setCurrentPage(0);
              onPageChange(e, 0);
            }}
          >
            1
          </Button>
        )}
        {some(left) && '...'}
        {center.map((index) => (
          <Button
            key={String(index)}
            highlight={index === currentPage}
            onClick={(e) => {
              setCurrentPage(index);
              onPageChange(e, index);
            }}
          >
            {index + 1}
          </Button>
        ))}
        {some(right) && '...'}
        {some(right) && (
          <Button
            highlight={currentPage === pages - 1}
            onClick={(e) => {
              setCurrentPage(pages - 1);
              onPageChange(e, pages - 1);
            }}
          >
            {pages}
          </Button>
        )}
        <Button
          onClick={(e) => {
            if (currentPage < pages - 1) {
              setCurrentPage(currentPage + 1);
              onPageChange(e, currentPage + 1);
            }
          }}
        >
          {'>'}
        </Button>
      </span>
    </div>
  );
}

Pagination.defaultProps = {
  onPageChange: () => null,
  displayedRange: 8,
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  displayedRange: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
