import React from 'react';
import Immutable, { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Submit } from '../form';
import qform from '../../core/components/form/queries';
import form from '../../core/components/form/actions';
import qcomm from '../../core/comm/queries';
import storage from '../../core/storage/actions';
import qstorage from '../../core/storage/queries';
import { insertInOnSuccess, insertInDispatch } from '../../core/util';
import { Response, ValidationRequest } from '../../core/comm';
import Form from '../Form';
import FormSection from './FormSection';
import Console from '../../core/util/console';

function setFormDefaultState(view, id, formState) {
  return storage.set(view, [`${id}@defaultState`], formState);
}

function getFormDefaultState(state, view, id) {
  return qstorage.get(state, view, [`${id}@defaultState`]);
}

function resetForm(view, id, formState) {
  return form.merge(view, id, formState);
}

function getData(data) {
  return data;
}

function insertOnError(submit, fn) {
  const onError = submit.getIn(['request', 'onError'], Response());
  const onValidation = submit.get('requiredValidations', ValidationRequest());
  const completeOnError = onError.setIn(
    ['requiredValidations'],
    insertInDispatch(onValidation, fn),
  );

  return submit.setIn(['request', 'onError'], completeOnError);
}

/**
 * Adiciona no onSuccess do submit a função de fechar o modo de edição.
 */
function updateSubmit(submit, setSectionEditing) {
  if (!submit.getIn(['request', 'onSuccess'])) {
    return submit.setIn(
      ['request', 'onSuccess'],
      Response({
        effect: true,
        dispatch: List([() => setSectionEditing(null)]),
      }),
    );
  }

  const dispatch = submit.getIn(['request', 'onSuccess', 'dispatch']);
  if (typeof dispatch === 'function') {
    return submit
      .setIn(
        ['request', 'onSuccess', 'dispatch'],
        List([dispatch, () => setSectionEditing(null)]),
      )
      .setIn(['request', 'onSuccess', 'effect'], true);
  }

  if (Immutable.isList(dispatch)) {
    return submit
      .setIn(
        ['request', 'onSuccess', 'dispatch'],
        dispatch.push(() => setSectionEditing(null)),
      )
      .setIn(['request', 'onSuccess', 'effect'], true);
  }

  return submit
    .setIn(['request', 'onSuccess', 'dispatch'], () => setSectionEditing(null))
    .setIn(['request', 'onSuccess', 'effect'], true);
}

function findChangedData(currentState, defaultState) {
  if (!currentState || !defaultState) {
    return Map();
  }
  return currentState.filter((value, key) => defaultState.get(key) !== value);
}

function SectionForm(props) {
  const {
    id,
    view,
    sections,
    doSubmit,
    onCancel,
    onSubmit,
    formState,
    resetForm,
    isSubmiting,
    communicating,
    sectionEditing,
    defaultFormState,
    setSectionEditing,
    setFormDefaultState,
  } = props;
  const validator =
    sectionEditing !== null ? sections[sectionEditing].validator : null;

  return (
    <Form id={id} view={view} validator={validator}>
      {sections.map((sectionProps, index) => (
        <FormSection
          index={index}
          {...sectionProps}
          onCancel={onCancel}
          doSubmit={(e) => {
            if (
              onSubmit(e, {
                changedValues: findChangedData(formState, defaultFormState),
                formState,
                doSubmit,
              }) !== false
            ) {
              return doSubmit(e);
            }
            return null;
          }}
          key={sectionProps.id}
          formState={formState}
          resetForm={resetForm}
          isSubmiting={isSubmiting}
          isEditing={sectionEditing}
          communicating={communicating}
          setIsEditing={setSectionEditing}
          defaultFormState={defaultFormState}
          setFormDefaultState={setFormDefaultState}
        />
      ))}
    </Form>
  );
}

// /////////////////////////////////////////////////////////
//
// Mapeamento
//
// /////////////////////////////////////////////////////////

function mapStateToProps(state, ownProps) {
  const { view, id = 'form', submit = Submit() } = ownProps;

  const resource = submit.getIn(['request', 'url']);

  return {
    fieldsSteps: qform.getFieldsSteps(state, view, id),
    communicating: qcomm.isCommunicating(state),
    isSubmiting: qcomm.isSending(state, view, resource),
    formState: qform.queryData(state, view, id, getData),
    defaultFormState: getFormDefaultState(state, view, id),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const {
    view,
    submit,
    id = 'form',
    invalidateCache,
    setSectionEditing,
    fieldSteps,
  } = ownProps;

  const actions = {
    setFormDefaultState: (formState) =>
      setFormDefaultState(view, id, formState),
    resetForm: (formState) => resetForm(view, id, formState),
  };

  if (!submit) {
    return actions;
  }

  let s = insertInOnSuccess(submit, () =>
    setFormDefaultState(view, id, null),
  ).setIn(['request', 'view'], view);
  if (invalidateCache) {
    s = s.set('invalidateCache', invalidateCache);
  }

  s = insertOnError(s, (data) => {
    if (data && !data.isEmpty()) {
      const firstKey = data.keySeq().toArray()[0];
      const step = fieldSteps.findIndex((fields) => fields.includes(firstKey));
      if (step === -1) {
        Console.warn(
          'Erro de validação em chave inexistente. Chave:',
          firstKey,
        );
      } else {
        setSectionEditing(step);
      }
    }
  });
  s = updateSubmit(s, setSectionEditing);
  const doSubmit = Form.createSubmitFn(id, s);
  return bindActionCreators({ doSubmit, ...actions }, dispatch);
}

// /////////////////////////////////////////////////////////
//
// PropTypes
//
// /////////////////////////////////////////////////////////

SectionForm.defaultProps = {
  id: 'form',
  invalidateCache: null,
  sectionEditing: null,
  onCancel: () => null,
  onSubmit: () => null,
  submit: Submit(),
};

SectionForm.propTypes = {
  id: PropTypes.string,
  sectionEditing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSectionEditing: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  view: PropTypes.string.isRequired,
  /**
   * Um objeto do tipo `Submit` que descreve o
   * processo de submissão dos dados do formulário.
   * A 'view' da 'request' e função de invalidação
   * de cache são configuradas automaticamente pela
   * FormView. A 'view' será a mesma passada nas
   * `formProps`.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  submit: PropTypes.instanceOf(Submit),

  // eslint-disable-next-line react/no-unused-prop-types
  invalidateCache: PropTypes.func,

  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      content: PropTypes.func,
      validator: PropTypes.func,
      onEdit: PropTypes.func,
      disabledEdit: PropTypes.func,
    }),
  ).isRequired,
};

// /////////////////////////////////////////////////////////
//
// Export
//
// /////////////////////////////////////////////////////////
const ConnectedSectionForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionForm);

function connectMapStateToProps(state, props) {
  const { id = 'form', view } = props;
  return {
    fieldSteps: qform.getFieldsSteps(state, view, id),
  };
}

function WrapperSectionForm(props) {
  return <ConnectedSectionForm {...props} />;
}

export default connect(connectMapStateToProps, null)(WrapperSectionForm);
