import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';
// @TODO Update: Material UI migration hotspot - vai virar hook
import withStyles from '@material-ui/core/styles/withStyles';
// @TODO Update: vai virar hook useMatchWidth (criar hook);
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { mask, format } from '../core/util';
// @TODO Update: como se livrar dessas referências diretas?
import { colors } from '../theme';

function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);
  return {
    root: {
      paddingLeft: u,
      paddingRight: u,
    },
    wrapper: {
      marginTop: 2 * u,
      marginRight: 2 * u,
      marginBottom: u,
    },
    titleDefault: {
      display: 'flex',
      fontSize: '0.8rem',
    },
    text: {
      paddingTop: u / 2,
    },
    icon: {
      width: '14px',
      height: '14px',
      alignSelf: 'center',
      marginLeft: u,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    textSecondary: {
      color: '#455a64',
    },
    grey: {
      color: colors.grey,
    },
    greyDark: {
      color: colors.greyDark,
    },
    success: {
      color: colors.success,
    },
    danger: {
      color: colors.danger,
    },
    dangerDark: {
      color: colors.dangerDark,
    },
    warning: {
      color: colors.warning,
    },
    bold: {
      fontWeight: 'bold',
    },
  };
}

function getPrefix(type, value, dense, width) {
  if (dense && (width === 'xs' || width === 'sm')) {
    return '';
  }
  if (value !== null && type === 'currency') {
    return value < 0 ? '- R$ ' : 'R$ ';
  }
  return '';
}

function getSufix(type, value) {
  if (value !== null && type === 'percentage') {
    return '%';
  }
  return '';
}

function getStyle(type, value, color, classes) {
  if (type === 'currency' && !color && value !== null) {
    return value < 0 ? classes.danger : classes.success;
  }

  if (color) {
    return classes[color];
  }

  return null;
}

function maskValue(type, value) {
  if (type === 'date' || type === 'datetime') {
    return format(type, value);
  }
  if (type === 'percentage') {
    return value || 0;
  }
  return mask(type, value);
}

function Mask(props) {
  const {
    type,
    bold,
    dense,
    width,
    value,
    label,
    color,
    style,
    classes,
    children,
    textStyle,
    iconLabel,
    iconColor,
    titleColor,
    titleStyle,
    infoMessage,
    titleClassName,
    startAdornment,
    ...others
  } = props;
  // @TODO Update: - vai virar hook useMatchWidth (criar hook)
  //               - transformar getPrefix na hook usePrefix
  const prefix = getPrefix(type, value, dense, width);
  const sufix = getSufix(type, value);
  const innerStyle = getStyle(type, value, color, classes);

  const IconLabel = iconLabel;

  let iconStyle = null;
  if (iconColor) {
    iconStyle = getStyle(null, null, iconColor, classes);
  }
  if (label) {
    const titleOtherStyle = titleColor
      ? getStyle(type, value, titleColor, classes)
      : innerStyle;
    return (
      <div style={style} className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            className={classNames(
              classes.titleDefault,
              titleOtherStyle,
              titleClassName,
            )}
            style={titleStyle}
          >
            {label}
            {iconLabel && (
              <IconLabel className={classNames(classes.icon, iconStyle)} />
            )}
            {infoMessage && (
              <Tooltip title={infoMessage}>
                <Info
                  style={{
                    width: '15px',
                    height: '15px',
                    margin: '0 2px -5px 8px',
                  }}
                />
              </Tooltip>
            )}
          </Typography>
          <Typography
            className={classNames(
              classes.text,
              innerStyle,
              bold && classes.bold,
            )}
            style={textStyle}
            {...others}
          >
            {value !== null
              ? `${prefix}${maskValue(type, value)}${sufix}`
              : '---'}
          </Typography>
          <Typography>{children}</Typography>
        </div>
      </div>
    );
  }

  return (
    <Typography
      style={textStyle}
      className={classNames(classes.text, innerStyle, bold && classes.bold)}
      {...others}
    >
      {value !== null ? `${prefix}${maskValue(type, value)}` : '---'}
    </Typography>
  );
}

Mask.defaultProps = {
  type: 'plain',
  value: null,
  label: null,
  color: null,
  bold: false,
  style: null,
  dense: false,
  titleColor: null,
  titleClassName: null,
  titleStyle: null,
  textStyle: null,
  iconLabel: null,
  iconColor: null,
  startAdornment: null,
};

Mask.propTypes = {
  type: PropTypes.oneOf([
    'percentage',
    'datetime',
    'currency',
    'phone',
    'plain',
    'date',
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  dense: PropTypes.bool,
  bold: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  titleClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  textStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  iconLabel: PropTypes.any,
  iconColor: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'textSecondary',
    'grey',
    'greyDark',
    'danger',
    'dangerDark',
    'success',
    'warning',
  ]),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'textSecondary',
    'grey',
    'greyDark',
    'danger',
    'dangerDark',
    'success',
    'warning',
  ]),
  titleColor: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'textSecondary',
    'grey',
    'greyDark',
    'danger',
    'dangerDark',
    'success',
    'warning',
  ]),
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(withWidth()(Mask));
