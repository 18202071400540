import { createSetObject } from '../../../core/util';
import { colors as moreColors, theme } from '../../../theme';

const stdColors = createSetObject(
  'success',
  'successDark',
  'danger',
  'dangerDark',
  'warning',
  'warningDark',
  'grey',
  'greyDark',
);

const themeColors = {
  primary: theme.palette.primary.main,
  primaryDark: theme.palette.primary.dark,
  primaryLight: theme.palette.primary.light,
  secondary: theme.palette.secondary.main,
  secondaryDark: theme.palette.secondary.dark,
};

function rowStatusColor(labelColor) {
  if (!labelColor) {
    return null;
  }

  let color = null;
  if (stdColors[labelColor]) {
    color = moreColors[labelColor];
  } else if (themeColors[labelColor]) {
    color = themeColors[labelColor];
  } else {
    color = labelColor;
  }

  return { backgroundColor: color };
}

export default rowStatusColor;
