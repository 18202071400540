import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  history: {
    display: "flex",
    background: "#E7EFF3",
    borderRadius: "4px",
    width: "100%",
    padding: "1rem",
    marginBottom: "1rem",
  },

  icon: {
    marginRight: "1rem",
  },
});
