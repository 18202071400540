import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import datatable from '../../core/components/datatable/actions';
import qdatatable from '../../core/components/datatable/queries';
import qstorage from '../../core/storage/queries';

function mapStateToProps(state, ownProps) {
  const { view, id = 'table' } = ownProps;
  const s = qdatatable.getState(state, view, id);

  return {
    page: s.get('page'),
    sorting: s.get('sorting'),
    pageSize: s.get('pageSize'),
    selectedRows: s.get('selectedRows'),
    detailedTuple: s.get('detailedTuple'),
    searchTerm: qstorage.get(state, view, [id, 'search'], ''),
    filters: qstorage.get(state, view, [id, 'filterForm', 'submit'], Map()),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, id = 'table' } = ownProps;

  return bindActionCreators(
    {
      setDetailedTuple: (tuple) => datatable.setDetailedTuple(view, id, tuple),
      setSelectedRows: (rows) => datatable.setSelectedRows(view, id, rows),
      onChangePageSize: (size) => datatable.setPageSize(view, id, size),
      onSort: (view, key) => datatable.setSorting(view, id, key),
      onPageChange: (page) => datatable.setPage(view, id, page),
      onToggleRow: (tupleID, tuple) =>
        datatable.toggleRow(view, id, tupleID, tuple),
    },
    dispatch,
  );
}

export { mapStateToProps, mapDispatchToProps };
