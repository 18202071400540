import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { mdiCheckBold } from '@mdi/js';
import Icon from '@mdi/react';
import Typography from '@material-ui/core/Typography';

function styles(theme) {
  return {
    root: {
      backgroundColor: '#ccc',
      transition: 'background-color 300ms ease-out',
      zIndex: 1,
      color: '#fff',
      width: 45,
      height: 45,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      background: theme.palette.primary.light,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      background: theme.palette.primary.light,
    },
    small: {
      width: 30,
      height: 30,
    },
  };
}

function StepIcon(props) {
  const { classes, active, completed, icon, isSmall } = props;

  return (
    <div
      className={classNames(
        classes.root,
        active && classes.active,
        completed && classes.completed,
        isSmall && classes.small,
      )}
    >
      {completed ? (
        <Icon path={mdiCheckBold} size={1} color="#fff" />
      ) : (
        <Typography color="inherit" variant={isSmall ? 'h6' : 'h5'}>
          <b>{icon}</b>
        </Typography>
      )}
    </div>
  );
}

export default withStyles(styles)(StepIcon);
