import { some, searchRegex, removeStringAccents } from '../../core/util';

function reducer(acc, predicate, tuple) {
  const condition = predicate.get('condition');
  const chosedValue = predicate.get('value');
  const op = predicate.get('operator');
  return Boolean(acc && condition(tuple, chosedValue, op));
}

function filterData(data, filters) {
  if (
    !data ||
    data.size === 0 ||
    filters.isEmpty() ||
    !filters.get('!@predicates') ||
    filters.get('!@predicates').size === 0
  ) {
    return data;
  }

  const predicates = filters.get('!@predicates');
  return data.filter((tuple) =>
    predicates.reduce((acc, v) => reducer(acc, v, tuple), true),
  );
}

/**
 * Filtra pelo termo inserido no campo de busca do componente `Base`.
 */
function filterBySearchTerm(data, stringfyTuple, term) {
  if (!data || !some(term) || term === '' || !stringfyTuple) {
    return data;
  }

  const regexp = searchRegex(removeStringAccents(term), 'gi');
  return data.filter((tuple) => {
    const tupleAsString = removeStringAccents(stringfyTuple(tuple));
    return Boolean(tupleAsString.match(regexp));
  });
}

export { filterData, filterBySearchTerm };
