import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
    backgroundColor: "#197997",
  },
  menuItem: {
    color: "#197997",
  },
  selectedItem: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.00)",
    paddingLeft: "0.8rem",
  },
  icon: {
    fill: "white",
    pointerEvents: "none",
    position: "absolute",
    right: "0.5rem",
  },
  underline: {
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
}));
