import React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import {
  Step,
  Button,
  StepLabel,
  Typography,
  StepContent,
  Stepper as MuiStepper,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import StepIcon from './StepIcon';
import StepConnector from './StepConnector';
import SubmitButton from '../form/SubmitButton';

function styles(theme) {
  return {
    optionalWrapper: {
      display: 'flex',
    },
    optional: {
      margin: 'auto',
    },
    active: {
      color: theme.palette.primary.light,
    },
    stepperV: {
      padding: 24,
      [theme.breakpoints.down('sm')]: {
        padding: `24px 0px`,
      },
    },
    button: {
      minWidth: '11rem',
      margin: '16px 0px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '7rem',
      },
    },
    buttonSmall: {
      margin: '16px 0px',
    },
  };
}

function Stepper(props) {
  const {
    steps,
    action,
    classes,
    noPadding,
    hideLabels,
    hideTimeline,
    orientation,
  } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(Set());
  const {
    content: stepContent,
    disabledNextButton,
    hideActions,
    backText,
    nextText,
    onEnter,
    onNext,
    onBack,
  } = steps[activeStep];

  const moveNext = () => {
    if (activeStep === steps.length - 1) {
      return;
    }
    setCompletedSteps(completedSteps.add(activeStep));
    setActiveStep(activeStep + 1);
  };
  const movePrev = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(activeStep - 1);
  };
  const reset = () => {
    setCompletedSteps(Set());
    setActiveStep(0);
  };

  React.useImperativeHandle(
    action,
    () => ({
      moveNext,
      movePrev,
      reset,
    }),
    [],
  );

  React.useEffect(() => {
    if (onEnter) {
      onEnter();
    }
  }, [activeStep]);

  const isVertical = orientation === 'vertical';

  return (
    <>
      {!hideTimeline && (
        <MuiStepper
          orientation={orientation || 'horizontal'}
          alternativeLabel={!isVertical}
          classes={{
            vertical: classes.stepperV,
          }}
          style={{
            background: 'transparent',
            padding: noPadding ? '0px' : '24px',
          }}
          activeStep={activeStep}
          connector={<StepConnector />}
        >
          {steps.map((step, index) => {
            const { title, subtitle, content } = step;
            const isActive = activeStep === index;
            const isComplete = completedSteps.contains(index);

            const optionalLabel = subtitle && (
              <div className={classes.optionalWrapper}>
                <Typography variant="caption" className={classes.optional}>
                  {subtitle}
                </Typography>
              </div>
            );

            if (isVertical) {
              return (
                <Step key={title}>
                  <StepLabel
                    optional={optionalLabel}
                    StepIconComponent={StepIcon}
                    StepIconProps={{ isSmall: true }}
                  >
                    {!hideLabels && (
                      <span>
                        {isActive || isComplete ? <b>{title}</b> : title}
                      </span>
                    )}
                  </StepLabel>
                  )
                  <StepContent>
                    {content && content()}
                    <div className={classes.actionsContainer}>
                      <div style={{ display: hideActions ? 'none' : 'flex' }}>
                        <Button
                          size="small"
                          variant="outlined"
                          disabled={activeStep === 0}
                          style={{ marginRight: 16 }}
                          className={classes.buttonSmall}
                          onClick={
                            onBack ? () => onBack({ movePrev }) : movePrev
                          }
                        >
                          {backText || 'Voltar'}
                        </Button>
                        <SubmitButton
                          size="small"
                          color="light"
                          align="right"
                          className={classes.buttonSmall}
                          disabled={activeStep === steps.length - 1}
                          onClick={
                            onNext ? () => onNext({ moveNext }) : moveNext
                          }
                        >
                          <b>{nextText || 'Continuar'}</b>
                        </SubmitButton>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              );
            }

            return (
              <Step key={title}>
                <StepLabel
                  StepIconComponent={StepIcon}
                  optional={optionalLabel}
                >
                  {!hideLabels && (
                    <span>
                      {isActive || isComplete ? <b>{title}</b> : title}
                    </span>
                  )}
                </StepLabel>
                )
              </Step>
            );
          })}
        </MuiStepper>
      )}
      {!isVertical && (
        <div style={{ padding: '24px' }}>
          {stepContent && stepContent()}
          <div
            style={{
              display: hideActions ? 'none' : 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              className={classes.button}
              onClick={onBack ? () => onBack({ movePrev }) : movePrev}
            >
              {backText || 'Voltar'}
            </Button>
            <SubmitButton
              color="light"
              align="right"
              className={classes.button}
              onClick={onNext ? () => onNext({ moveNext }) : moveNext}
              disabled={activeStep === steps.length - 1 || disabledNextButton}
            >
              <b>{nextText || 'Continuar'}</b>
            </SubmitButton>
          </div>
        </div>
      )}
    </>
  );
}
Stepper.defaultProps = {
  orientation: 'horizontal',
  hideTimeline: false,
  hideLabels: false,
  noPadding: false,
  action: {},
};

Stepper.propTypes = {
  /**
   * Referência do `Stepper`
   */
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.object,
  /**
   * Orientação do `Stepper` (horizontal ou vertial)
   */
  orientation: PropTypes.string,
  /**
   * Oculta labels de steps
   */
  hideLabels: PropTypes.bool,
  /**
   * Oculta timeline de steps
   */
  hideTimeline: PropTypes.bool,
  /**
   * Se `true` template não possui padding
   */ noPadding: PropTypes.bool,
  /**
   * Steps
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      onBack: PropTypes.func,
      onNext: PropTypes.func,
      title: PropTypes.string,
      content: PropTypes.func,
      onEnter: PropTypes.func,
      backText: PropTypes.string,
      nextText: PropTypes.string,
      hideActions: PropTypes.bool,
      disabledNextButton: PropTypes.bool,
    }),
  ).isRequired,
};

export default withStyles(styles)(Stepper);
