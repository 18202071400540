import React from 'react';
import { Tooltip, TableCell, Checkbox } from '@material-ui/core';
import { Selection } from '../util';

function MasterCheckBoxCell(props) {
  const {
    selection,
    className,
    numberOfRows,
    isLargeScreen,
    onToggleAllRows,
  } = props;
  const checked = selection !== 'none';
  const opt = checked ? 'desselecionar' : 'selecionar';
  const tooltip = `${opt} todos ${numberOfRows} registros`;

  return (
    <TableCell
      className={className}
      padding={isLargeScreen ? 'default' : 'checkbox'}
    >
      <Tooltip title={tooltip}>
        <Checkbox
          color="primary"
          checked={checked}
          onClick={onToggleAllRows}
          indeterminate={selection === Selection.SOME}
        />
      </Tooltip>
    </TableCell>
  );
}

export default MasterCheckBoxCell;
