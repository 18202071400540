// TODO remove isso

/* eslint-disable react/forbid-prop-types */
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

import Input from "./Input";

function styles(theme) {
  const u = theme.spacing(1);

  return {
    gridItem: {
      paddingLeft: u,
      paddingRight: u,
    },
  };
}

function GridInput(props) {
  const {
    xs = 12,
    sm = false,
    md = false,
    lg = false,
    xl = false,
    style,
    classes,
    noPadding,
    title,
    ...other
  } = props;

  const sizes = { xs, sm, md, lg, xl };
  return (
    <Grid item {...sizes} className={noPadding ? "" : classes.gridItem} style={style}>
      {title && (
        <Typography variant="h6" color="primary" style={{ marginBottom: "-1rem" }}>
          {title}
        </Typography>
      )}
      <Input {...other} />
    </Grid>
  );
}

GridInput.defaultProps = {
  xs: 12,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  noPadding: false,
};

const colValues = [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

GridInput.propTypes = {
  ...Input.propTypes,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.object,
  xs: PropTypes.oneOf(colValues),
  sm: PropTypes.oneOf(colValues),
  md: PropTypes.oneOf(colValues),
  lg: PropTypes.oneOf(colValues),
  xl: PropTypes.oneOf(colValues),
  noPadding: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridInput);
