import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Tooltip } from '@material-ui/core';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  return {
    root: {
      height: 7,
      borderRadius: 20,
      backgroundColor: '#D3E6EC',
    },
    bar: {
      borderRadius: 20,
      backgroundColor: theme.palette.primary.light,
    },
  };
}

function ProgressBar(props) {
  const { value, color, style, tooltip, classes } = props;
  const Wrapper = tooltip ? Tooltip : React.Fragment;

  return (
    <Wrapper>
      <LinearProgress
        classes={{ root: classes.root, bar: classes.bar }}
        variant="determinate"
        color={color}
        value={value}
        style={style}
      />
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(ProgressBar);
