import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { colors as themeColors } from '../../theme';

function styles(theme) {
  return {
    center: {
      justifyContent: 'center',
      display: 'flex',
      flexGrow: 1,
    },
    right: {
      display: 'inline-block',
    },
    wrapper: {
      position: 'relative',
    },
    loading: {
      color: themeColors.success,
      position: 'absolute',
      marginLeft: -12,
      marginTop: -12,
      left: '50%',
      top: '50%',
    },
    button: {
      fontWeight: 'bold',
    },
    lightButton: {
      backgroundColor: theme.palette.primary.light,
    },
  };
}

function SubmitButton(props) {
  const {
    color,
    align,
    onClick,
    classes,
    loading,
    disabled,
    children,
    disableSubmit,
    className,
    style,
    ...others
  } = props;
  const isLightColor = color === 'light';

  return (
    <div className={align === 'center' ? classes.center : classes.right}>
      <div className={classes.wrapper}>
        <Button
          disabled={disabled || disableSubmit}
          className={classNames(
            classes.button,
            isLightColor && classes.lightButton,
            className,
          )}
          variant="contained"
          onClick={onClick}
          style={style}
          color={isLightColor ? 'primary' : color}
          {...others}
        >
          {children}
          {loading && disabled && (
            <CircularProgress size={24} className={classes.loading} />
          )}
        </Button>
      </div>
    </div>
  );
}

SubmitButton.defaultProps = {
  disabled: false,
  align: 'center',
  loading: false,
  onClick: null,
  size: 'large',
  color: 'primary',
  style: null,
};

SubmitButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  align: PropTypes.oneOf(['center', 'right']),
  color: PropTypes.oneOf(['primary', 'secondary', 'light']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default withStyles(styles)(SubmitButton);
