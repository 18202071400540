import { Input, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ExpandMore } from "@material-ui/icons";
import Immutable from "immutable";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

export function CustomSelect({ options, value, onChange }) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={onChange}
        input={
          <Input
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            classes={{ underline: classes.underline }}
          />
        }
        MenuProps={{
          classes: { paper: classes.menuItem },
        }}
        inputProps={{
          className: classes.selectedItem,
        }}
        IconComponent={(props) => <ExpandMore {...props} className={classes.icon} />}
      >
        {options.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={index} value={option.get("value")} className={classes.menuItem}>
            {option.get("label")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.instanceOf(Immutable.List).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

CustomSelect.defaultProps = {
  value: "",
  onChange: () => {},
};
