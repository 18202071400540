import { Map, Set } from 'immutable';

const Selection = {
  NONE: 'none',
  SOME: 'some',
  ONE: 'one',
  ALL: 'all',
};

function getSelection(numberOfRows, selectedCount) {
  // verifica se alguma linha foi marcada
  // com um checkbox
  if (selectedCount === 0) {
    return Selection.NONE;
  }
  // verifica se somente alguns [não todos]
  // foram marcados
  if (selectedCount > 0 && selectedCount < numberOfRows) {
    return Selection.SOME;
  }
  return Selection.ALL;
}

function createOnToggleAllRows(params) {
  const {
    setSelectedRows,
    selectedRows,
    onSelect,
    selection,
    idField,
    data,
  } = params;

  return (event) => {
    if (selection !== Selection.ALL) {
      const rfn = (acc, tuple) => acc.set(tuple.get(idField), tuple);
      setSelectedRows(data.reduce(rfn, Map()));
      if (onSelect) {
        onSelect(event, {
          selected: data,
          selection: Selection.ALL,
          previous: Set(selectedRows.valueSeq()),
        });
      }
    } else {
      setSelectedRows(Map());
      if (onSelect) {
        onSelect(event, {
          selected: null,
          selection: Selection.NONE,
          previous: Set(selectedRows.valueSeq()),
        });
      }
    }
  };
}

function getHandleClickRow(actions, onClickRow, history) {
  if (typeof onClickRow !== 'string') {
    return onClickRow;
  }

  const { onClick, link } = actions.find((a) => a.tooltip === onClickRow) || {};
  if (!link) {
    return onClick;
  }
  return (e, tuple, index) => {
    onClick(e, tuple, index);
    if (history) {
      history.push(typeof link === 'function' ? link(tuple) : link);
    }
  };
}

export { Selection, getSelection, createOnToggleAllRows, getHandleClickRow };
