import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import IconButton from '../../IconButton';
import MasterCheckBoxCell from './MasterCheckBoxCell';

function styles(theme) {
  return {
    cell: {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.grey[50],
    },
  };
}

function ActionRow(props) {
  const {
    classes,
    loading,
    colSpan,
    hasStatus,
    selection,
    selectable,
    hasActions,
    batchActions,
    numberOfRows,
    selectedRows,
    isLargeScreen,
    onToggleAllRows,
  } = props;

  const needSpaceForActions = colSpan > 1 && hasActions;

  return (
    <TableRow>
      {hasStatus && !loading && (
        <TableCell padding="none" className={classes.cell} />
      )}
      {selectable && !loading && (
        <MasterCheckBoxCell
          onToggleAllRows={onToggleAllRows}
          isLargeScreen={isLargeScreen}
          numberOfRows={numberOfRows}
          className={classes.cell}
          selection={selection}
        />
      )}
      <TableCell
        colSpan={needSpaceForActions ? colSpan - 1 : colSpan}
        className={classes.cell}
        padding={isLargeScreen ? 'default' : 'checkbox'}
      >
        {numberOfRows === selectedRows.size
          ? `Todos ${numberOfRows} registros foram selecionados`
          : `${selectedRows.size} registros foram selecionados.`}
      </TableCell>
      {batchActions && (
        <TableCell
          colSpan={needSpaceForActions ? 2 : 1}
          className={classes.cell}
          padding={isLargeScreen ? 'default' : 'checkbox'}
        >
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {batchActions &&
              batchActions.map((a) => {
                const { icon, onClick = () => null, ...rest } = a;
                return (
                  <IconButton
                    {...rest}
                    key={a.tooltip}
                    onClick={(e) => onClick(e, selectedRows.valueSeq())}
                  >
                    {icon}
                  </IconButton>
                );
              })}
          </span>
        </TableCell>
      )}
    </TableRow>
  );
}

export default withStyles(styles)(ActionRow);
