import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import InfoIcon from '@material-ui/icons/Info';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// eslint-disable-next-line camelcase
import { ATROCITY_some } from '../core/util';

function styles(theme) {
  return {
    selected: {
      '&&': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    selectDisabled: {
      '&&': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    toggle: {
      color: theme.palette.text.primary,
      textTransform: 'none',
      fontSize: '.875rem',
      padding: '2px 12px',
      height: 'auto',
      minWidth: '150px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100px',
      },
    },
    toggleGroup: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      display: 'inline-table',
    },
  };
}

function renderIconInfo(props) {
  const { label, value, status, message, width } = props;

  return (
    <>
      <div style={{ width: width === 'xs' && '300px' }}>
        {label}
        {(value || status === 'on') && (
          <Tooltip title={message}>
            <InfoIcon
              style={{
                width: '22px',
                height: '22px',
                margin: '0 2px -5px 8px',
              }}
            />
          </Tooltip>
        )}
      </div>
    </>
  );
}

function ToggleSwitch(props) {
  const {
    value,
    disabled,
    defaultValue,
    onChange,
    onBlur,
    minWidth,
    classes,
    onYes,
    onNo,
    yesText,
    noText,
    error,
    required,
    label,
    message,
    helperText,
  } = props;

  const options = List([
    Map({ label: noText, value: 'n', onSelect: onNo }),
    Map({ label: yesText, value: 's', onSelect: onYes }),
  ]);

  return (
    <FormControl component="fieldset" style={{ marginTop: '16px 0px 8px' }}>
      {label && (
        <FormLabel
          component="legend"
          error={error}
          disabled={disabled}
          required={required}
          style={{
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left',
            marginBottom: '8px',
          }}
        >
          {message ? renderIconInfo(props) : label}
        </FormLabel>
      )}
      <ToggleButtonGroup
        exclusive
        size="small"
        value={value || defaultValue ? 's' : 'n'}
        classes={{ root: classes.toggleGroup }}
        onChange={(e, v) => {
          if (!ATROCITY_some(v)) {
            return;
          }
          if (v) {
            onYes(e);
          } else {
            onNo(e);
          }
          onChange(e, v === 's');
          onBlur(e, v === 's');
        }}
      >
        {options.map((opt) => (
          <ToggleButton
            disabled={disabled}
            key={opt.get('label')}
            value={opt.get('value')}
            style={minWidth && { minWidth }}
            classes={{
              root: classes.toggle,
              selected: !disabled ? classes.selected : classes.selectDisabled,
            }}
          >
            {opt.get('label')}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

ToggleSwitch.defaultProps = {
  minWidth: undefined,
  onYes: () => null,
  onNo: () => null,
  label: null,
  helperText: null,
  yesText: 'Sim',
  noText: 'Não',
  defaultValue: false,
};

ToggleSwitch.prototypes = {
  minWidth: PropTypes.number,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,

  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleSwitch);
