import { Grid, withStyles, withWidth } from "@material-ui/core";
import { List } from "immutable";
import "moment/locale/pt-br";
import PropTypes from "prop-types";
import React from "react";

import IconButton from "../IconButton";
import renderInputComponent from "./render-component";

// /////////////////////////////////////////////////////////
//
// Styles
//
// /////////////////////////////////////////////////////////

function styles(theme) {
  const u = theme.spacing(1);
  const { main: primary } = theme.palette.primary;

  return {
    select: { minWidth: 200 },
    mainAction: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
    mainActionIcon: {
      color: "#fff", // primary,
      borderRadius: "50%",
      backgroundColor: primary,
    },
    marginControl: { marginTop: 2 * u },
    marginDateField: {
      marginTop: 2 * u,
      marginRight: 2 * u,
      marginBottom: u,
      width: "100%",
    },
    marginDefault: {
      margin: u,
      minWidth: "200px",

      "& span": {
        marginRight: "auto",
      },
    },
  };
}

// /////////////////////////////////////////////////////////
//
// Componente externo
//
// /////////////////////////////////////////////////////////

/**
 * Campo de Input customizado para o Sua Formatura. Diferenciais:
 *
 * - campos do tipo password já vem o botão de mostrar e esconder;
 *   a senha por padrão;
 * - existem tipo especiais como CPF (veja os `propTypes`);
 * - campo são mascarados por padrão;
 * - suporta campos como `ControlGroup` e `Autocomplete`;
 * - campos do tipo `currency` tem o `startAdornment` 'R$';
 * - `error` é uma string contendo uma mensagem de erro (para não
 *   confundir com [helperText](https://material-ui.com/api/text-field/),
 *   que exibida no mesmo lugar);
 * - suporte á `action` um botão que é renderizado à direta do campo;
 *
 * Uma mensagem de erro `error` só é exibida quando o campo perde o foco
 * ou quando `showError` for `true`.
 */
function Input(props) {
  const {
    type,
    width,
    focus,
    error,
    style,
    sloppy,
    action,
    showError,
    helperText,
    label,
    mobileLabel,
    defaultValue, // prop não passada adiante
    ...inputComponentProps
  } = props;
  const { disabled } = props;
  const isPassword = type === "password";
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const isErrorState = Boolean(!disabled && error && (showError || focus === "off"));

  const inputElement = renderInputComponent({
    isPassword,
    error: isErrorState,
    isPasswordVisible: passwordVisible,
    helperText: isErrorState ? error : helperText,
    type: isPassword && passwordVisible ? "text" : type,
    style: { width: "100%", marginRight: "16px", ...style },
    onVisibilityChange: () => setPasswordVisible(!passwordVisible),
    label: mobileLabel && ["xs", "sm"].includes(width) ? mobileLabel : label,
    ...inputComponentProps,
  });

  if (!action) {
    return inputElement;
  }

  const { icon, component, ...actionProps } = action;
  const ActionComponent = component;
  return (
    <Grid container alignItems="flex-end" style={{ height: "100%" }}>
      <Grid item xs={sloppy ? 10 : 11}>
        {inputElement}
      </Grid>
      <Grid item xs={sloppy ? 2 : 1}>
        {component ? (
          <ActionComponent {...action} />
        ) : (
          <IconButton {...actionProps}>{icon}</IconButton>
        )}
      </Grid>
    </Grid>
  );
}

Input.defaultProps = {
  autoComplete: null,
  defaultValue: null,
  mobileLabel: null,
  autoFocus: false,
  helperText: null,
  showError: false,
  mainOption: null,
  options: null,
  sloppy: false,
  type: "text",
  action: null,
  label: null,
  error: null,
  focus: null,
};

Input.propTypes = {
  /**
   * Tipos possível para o campo. Perceba que
   * nem todos são campos de texto, por exemplo:
   * autocomplete e radio.
   */
  type: PropTypes.oneOf([
    "toggle-switch",
    "autocomplete",
    "multiselect",
    "percentage",
    "chipcheck",
    "checkbox",
    "currency",
    "datetime",
    "password",
    "filelist",
    "counter",
    "toggle",
    "select",
    "number",
    "phone",
    "month",
    "radio",
    "cnpj",
    "date",
    "file",
    "text",
    "cpf",
    "cgc",
    "cep",
  ]),
  /**
   * Propriedade do botão de ação (que só é
   * renderizado se o valor for diferente de
   * `null`).
   */
  action: PropTypes.shape({ icon: PropTypes.node, ...IconButton.propTypes }),
  /**
   * [Label](https://material-ui.com/api/text-field/).
   */
  label: PropTypes.string,
  /**
   * label que será usado para telas mobile.
   */
  mobileLabel: PropTypes.string,
  /**
   * Descreve se o foco está ou não no campo. O
   * valor inicial é `null`.
   */
  focus: PropTypes.oneOf(["on", "off"]),
  /**
   * Se `true` força a exibição de uma mensagem de
   * erro e 'pinta' o campo de vermelho, caso haja
   * uma mensagem de erro.
   */
  showError: PropTypes.bool,
  /**
   * Uma mensagem de erro.
   */
  error: PropTypes.string,
  /**
   * Uma mensagem de ajuda para o campo.
   */
  helperText: PropTypes.string,
  /**
   * [autoComplete](https://material-ui.com/api/text-field/).
   */
  autoComplete: PropTypes.string,
  /**
   * [autoFocus](https://material-ui.com/api/text-field/).
   */
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Gambiarra para que o botão de actions fique lindo
   * no FilterForm
   */
  sloppy: PropTypes.bool,
  /**
   * TODO
   */
  options: PropTypes.instanceOf(List),
  /**
   * TODO
   */
  mainOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selectable: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(React.Component)]),
  }),
};

export default withStyles(styles)(withWidth()(Input));
