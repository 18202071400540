import React from 'react';
import classNames from 'classnames';
import { Collapse, Grid, withStyles } from '@material-ui/core';
import SubmitButton from '../form/SubmitButton';

function styles(theme) {
  const mdDown = theme.breakpoints.down('md');
  const xsDown = theme.breakpoints.down('xs');
  return {
    grid: { float: 'right', paddingTop: '0px', paddingBottom: '0px' },
    button: {
      fontWeight: 'bold',
      float: 'right',
      marginTop: '16px',
      marginBottom: '16px',
      minWidth: '170px',
      padding: '8px 20px',
      [mdDown]: {
        minWidth: '150px',
      },
      [xsDown]: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    submitButton: {
      backgroundColor: theme.palette.primary.light,
    },
    cancelButton: {
      color: '#777777',
      borderColor: '#777777',
      marginRight: '16px',
      [xsDown]: {
        marginRight: '0px',
      },
    },
  };
}

function SectionAction(props) {
  const {
    classes,
    onClose,
    doSubmit,
    editFields,
    isSubmiting,
    communicating,
  } = props;

  return (
    <Grid item xs={12} className={classes.grid}>
      <Collapse in={editFields} style={{ float: 'right' }}>
        <SubmitButton
          align="right"
          onClick={onClose}
          variant="outlined"
          className={classNames(classes.button, classes.cancelButton)}
        >
          Cancelar
        </SubmitButton>
        <SubmitButton
          align="right"
          color="primary"
          variant="contained"
          loading={isSubmiting}
          disabled={communicating}
          onClick={doSubmit}
          className={classNames(classes.button, classes.submitButton)}
        >
          Salvar
        </SubmitButton>
      </Collapse>
    </Grid>
  );
}

export default withStyles(styles)(SectionAction);
