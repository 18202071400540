import React from 'react';
import { Grid } from '@material-ui/core';
import SectionTitle from './SectionTitle';
import SectionIcon from './SectionIcon';
import ActionMenu from '../ActionMenu';
import ActionButton from '../ActionButton';

function itemsSizes(icon, headerRightSide, largeHeaderRightSide) {
  if (icon && headerRightSide) {
    return {
      normal: [6, 5, 1],
      small: [12, 12, 12],
    };
  }
  if (icon) {
    return {
      normal: [11, 0, 1],
      small: [11, 0, 1],
    };
  }
  if (headerRightSide) {
    return {
      normal: [largeHeaderRightSide ? 4 : 6, largeHeaderRightSide ? 8 : 6, 0],
      small: [12, 12, 0],
    };
  }
  return {
    normal: [12, 0, 0],
    small: [12, 0, 0],
  };
}

function Header(props) {
  const {
    icon,
    title,
    status,
    anchor,
    actions,
    setAnchor,
    titleIcon,
    titleColor,
    helperText,
    headerGutter,
    iconBackground,
    headerRightSide,
    largeHeaderRightSide,
  } = props;
  const { small, normal } = itemsSizes(
    actions || icon,
    headerRightSide,
    largeHeaderRightSide,
  );
  const [mdLeft, mdCenter, mdRight] = normal;
  const [xsLeft, xsCenter, xsRight] = small;

  return (
    <Grid container style={{ marginBottom: headerGutter ? 8 : 0 }}>
      <Grid item md={mdLeft} xs={xsLeft}>
        <SectionTitle
          status={status}
          helperText={helperText}
          titleColor={titleColor}
          titleIcon={titleIcon}
          title={title}
        />
      </Grid>
      {headerRightSide && !actions && (
        <Grid item md={mdCenter} xs={xsCenter}>
          {headerRightSide}
        </Grid>
      )}
      {actions && (
        <Grid item md={mdRight} xs={xsRight}>
          <ActionButton
            actions={actions}
            setAnchor={setAnchor}
            style={{ float: 'right' }}
          />
          <ActionMenu
            id="sectionActionMenu"
            anchor={anchor}
            actions={actions}
            onClose={() => setAnchor(null)}
          />
        </Grid>
      )}
      {icon && (
        <Grid item md={mdRight} xs={xsRight}>
          <SectionIcon icon={icon} iconBackground={iconBackground} />
        </Grid>
      )}
    </Grid>
  );
}

export default Header;
