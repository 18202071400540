import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import MasterCheckBoxCell from './MasterCheckBoxCell';
import Cell from './Cell';

function styles(theme) {
  return {
    cell: {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.grey[50],
    },
    actionCell: {
      width: 30,
    },
  };
}

function SchemaRow(props) {
  const {
    schema,
    onSort,
    classes,
    sorting,
    loading,
    hasData,
    selection,
    hasStatus,
    selectable,
    numberOfRows,
    isLargeScreen,
    onToggleAllRows,
    detailsSubTable,
    showActionsColumn,
  } = props;
  return (
    <TableRow>
      {hasStatus && !loading && hasData && (
        <TableCell padding="none" className={classes.cell} />
      )}
      {selectable && !loading && (
        <MasterCheckBoxCell
          onToggleAllRows={onToggleAllRows}
          isLargeScreen={isLargeScreen}
          numberOfRows={numberOfRows}
          className={classes.cell}
          selection={selection}
        />
      )}
      {schema.map((s) => {
        const { id, label, sort, align } = s;

        return (
          <Cell
            key={id}
            label={label}
            align={align}
            className={classes.cell}
            isLargeScreen={isLargeScreen}
            onSort={sort && (() => onSort(id))}
            sorting={
              !sort
                ? null
                : {
                    active: id === sorting.get('key'),
                    direction: sorting.get('direction') || 'desc',
                  }
            }
          />
        );
      })}
      {Boolean(detailsSubTable) && (
        <Cell
          key="detailSubTable"
          label=" "
          className={classes.cell}
          isLargeScreen={isLargeScreen}
        />
      )}
      {showActionsColumn && (
        <TableCell
          className={classNames(classes.cell, classes.actionCell)}
          align="right"
        />
      )}
    </TableRow>
  );
}

export default withStyles(styles)(SchemaRow);
