import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { colors } from '../theme';

function MdiAdapter(props) {
  const { icon, size, color, ...rest } = props;

  return <Icon size={size} path={icon} color={colors[color]} {...rest} />;
}

MdiAdapter.defaultProps = {
  color: 'grey',
  size: 1,
};

MdiAdapter.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default MdiAdapter;
