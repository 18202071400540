import React from 'react';
import MuiStepConnector from '@material-ui/core/StepConnector';
import withStyles from '@material-ui/core/styles/withStyles';

function styles(theme) {
  return {
    alternativeLabel: {
      top: 20.5,
    },
    active: {
      '& $lineHorizontal': {
        background: theme.palette.primary.light,
      },
    },
    completed: {
      '& $lineHorizontal': {
        background: theme.palette.primary.light,
      },
    },
    lineHorizontal: {
      height: 4,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
    lineVertical: {
      display: 'none',
    },
  };
}

function StepConnector(props) {
  const { classes, ...others } = props;
  return <MuiStepConnector classes={classes} {...others} />;
}

export default withStyles(styles)(StepConnector);
