import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input from './Input';
import { Filter } from '../core/comm';
import storage from '../core/storage/actions';
import qstorage from '../core/storage/queries';

function makeFilter(filterState) {
  const keys = filterState.keySeq();

  return keys.map((k) =>
    Filter({
      value: filterState.get(k) || '',
      field: k,
      op: '=',
    }),
  );
}

function createHandleChange(name, onChange, setFilter, filterState) {
  return (e) => {
    const { value } = e.target;
    setFilter(name, value);
    onChange(makeFilter(filterState.set(name, value)));
  };
}

function SimpleFilterForm(props) {
  const { setFilter, filterState, filters, onChange } = props;

  return (
    // @TODO Update: Material UI migration hotspot - vai virar hook
    <Grid container spacing={1}>
      {filters.map(({ name, label, options }) => (
        <Grid key={`${name}:${label}`} item xs={12} md={3} xl={2}>
          <Input
            type="select"
            name={name}
            label={label}
            options={options}
            value={filterState.get(name) || ''}
            onChange={createHandleChange(
              name,
              onChange,
              setFilter,
              filterState,
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const key = '@!SimpleFilterForm';

function mapStateToProps(state, ownProps) {
  const { id, view } = ownProps;

  return {
    filterState: qstorage.get(state, view, [key, id]) || Map({}),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { id, view } = ownProps;

  return bindActionCreators(
    {
      setFilter: (name, value) => storage.set(view, [key, id, name], value),
    },
    dispatch,
  );
}

SimpleFilterForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  view: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.instanceOf(List),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleFilterForm);
