import MomentUtils from "@date-io/moment";
import { ListItemIcon, MenuItem, TextField, Tooltip } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import { Set } from "immutable";
import {
  InlineDatePicker,
  InlineDateTimePicker,
  MuiPickersUtilsProvider,
} from "material-ui-pickers";
import Moment from "moment";
import "moment/locale/pt-br";
import React from "react";

// eslint-disable-next-line camelcase
import { ATROCITY_some, mask } from "../../core/util";
import Autocomplete from "../Autocomplete2";
import ControlGroup from "../ControlGroup";
import FileChooser from "../FileChooser";
import FileList from "../FileList";
import ToggleSwitch from "../ToggleSwitch";
import { isControlGroupType, isDateType, isTextFieldType } from "./predicates";
import { setInputProps, setShrinkLabel } from "./props";

const MAIN_OPTION_VALUE = "@!MAIN_OPTION_VALUE";

function renderIconInfo(props) {
  const { label, message } = props;

  return (
    <div>
      {label}
      <Tooltip title={message}>
        <Info
          style={{
            width: "22px",
            height: "22px",
            margin: "0 2px -5px 8px",
          }}
        />
      </Tooltip>
    </div>
  );
}

function renderMainOption(mainOption, classes) {
  if (!mainOption) {
    return null;
  }

  const { icon: Icon, label, onClick } = mainOption;
  return (
    <MenuItem onClick={onClick} value={MAIN_OPTION_VALUE} className={classes.mainAction}>
      {Icon && (
        <ListItemIcon className={classes.mainActionIcon}>
          <Icon />
        </ListItemIcon>
      )}
      {label}
    </MenuItem>
  );
}

/**
 * GAMBIARRA!!!
 *
 * ISSO É UMA GAMBIARRA PORQUE ALGUÉM TEVE A IDEIA BRILHANTE!!!!!!!!!!
 * DE USAR O COMPONENTE Form PARA FAZER FILTROS!!!!!!!!!!!!!!!!!!!!!!!
 * VIVA A CRIATIVIDADE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
let kkkk = 0;

function renderSelect(props, classes) {
  const {
    label,
    options,
    onChange,
    message,
    className,
    mainOption,
    style,
    minWidth = "150px",
    ...other
  } = props;

  const augmentedStyle = { ...style, minWidth };

  return (
    <TextField
      select
      className={classNames(className, classes.select)}
      SelectProps={{ MenuProps: { className: classes.select } }}
      onChange={(x, y) => {
        const { value } = x.target;

        if (value === MAIN_OPTION_VALUE && mainOption) {
          if (mainOption.onClick) {
            mainOption.onClick();
          }
          if (mainOption.selectable) {
            onChange(x, y);
          }
        } else {
          onChange(x, y);
        }
      }}
      {...other}
      style={augmentedStyle}
      label={message ? renderIconInfo(props) : label}
    >
      {renderMainOption(mainOption, classes)}
      {options &&
        options.map((opt) => {
          kkkk += 1;
          return (
            <MenuItem key={`${opt.get("value")}-${kkkk}`} value={opt.get("value")}>
              {opt.get("label")}
            </MenuItem>
          );
        })}
    </TextField>
  );
}

function renderControlGroup(props) {
  const { classes, ...others } = props;
  return (
    <div className={classes.marginControl}>
      <ControlGroup {...others} />
    </div>
  );
}

function getDateViews(views, isMonth, maxDate, minDate) {
  if (views || !isMonth) {
    return views;
  }
  if (maxDate && minDate) {
    const isThisYear = Moment.duration(minDate.diff(maxDate)).years() === 0;
    if (isThisYear) {
      return ["month"];
    }
  }
  return ["year", "month"];
}

function renderDateFieldInput(props) {
  const {
    type,
    value,
    views,
    label,
    error,
    openTo,
    onBlur,
    minDate,
    classes,
    maxDate,
    onFocus,
    variant,
    message,
    onChange,
    disabled,
    required,
    InputProps,
    noKeyboard,
    helperText,
    disablePast,
    placeholder,
    customFormat,
    disableFuture,
    InputLabelProps,
    customMask = null,
  } = props;

  const dateFormat = {
    date: "DD/MM/YYYY",
    month: "MM/YYYY",
    datetime: "DD/MM/YYYY [às] HH:mm",
  };

  const DateComponent = type === "datetime" ? InlineDateTimePicker : InlineDatePicker;
  const currentFormat = customFormat || dateFormat[type];
  const kb = !noKeyboard;

  const customViews = getDateViews(views, type === "month", minDate, maxDate);
  // const currentMask = !kb ? null : mask(type, value);

  let currentMask = customMask;
  if (kb && !customMask) {
    currentMask = mask(type, value);
  }

  // O campo value precisa ser nulo para ser limpo
  const newValue = value || null;

  return (
    <div className={classes.marginDateField}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <DateComponent
          clearable
          openTo={type === "month" ? customViews[0] : openTo}
          variant={variant}
          keyboard={kb}
          fullWidth
          views={customViews}
          error={error}
          helperText={helperText}
          value={newValue}
          onChange={(e) => {
            onChange(e);
            if (onBlur) {
              onBlur(e);
            }
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          label={message ? renderIconInfo(props) : label}
          placeholder={placeholder || Moment().format(currentFormat)}
          format={currentFormat}
          mask={currentMask}
          invalidDateMessage="Data inválida"
          minDateMessage="Data mínima ultrapassada"
          maxDateMessage="Data máxima ultrapassada"
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

function renderTextFieldInput(props) {
  const {
    min,
    max,
    type,
    label,
    value,
    noMask,
    shrink,
    classes,
    message,
    onChange,
    valueSize,
    inputProps,
    mainOption,
    InputProps,
    isPassword,
    InputLabelProps,
    readOnly = false,
    margin = "normal",
    isPasswordVisible,
    onVisibilityChange,
    ...other
  } = props;
  const { variant } = props;

  const newInputLabelProps = setShrinkLabel(InputLabelProps, shrink, type, value, variant);
  const newInputProps = setInputProps(
    InputProps,
    type,
    readOnly,
    onVisibilityChange,
    isPasswordVisible,
    isPassword,
    value,
    onChange,
    min,
    max,
  );

  let newProps = {
    type,
    margin,
    onChange,
    InputLabelProps: newInputLabelProps,
    InputProps: newInputProps,
    value: noMask ? value || "" : mask(type, value),
  };

  if (type === "counter") {
    const counterProps = {
      inputProps: {
        style: { textAlign: "center" },
        readOnly: true,
      },
      InputLabelProps: { shrink: true },
    };
    if (!newProps.value) {
      counterProps.value = min || 0;
    }
    newProps = { ...newProps, ...counterProps };
  } else if (type === "percentage") {
    newProps = {
      ...newProps,
      value: ATROCITY_some(value) ? value : "",
      inputProps: {
        style: {
          "& WebkitOuterSpinButton, & WebkitInnerSpinButton": {
            display: "none",
            WebkitAppearance: "none",
          },
        },
      },
    };
  } else if (type === "number") {
    newProps = {
      ...newProps,
      inputProps: {
        min,
        max,
        ...inputProps,
      },
    };
  } else if (type === "autocomplete") {
    const { style } = other;
    const fullStyle = { ...style, flexWrap: "nowrap" };
    newProps = { ...newProps, style: fullStyle };
  }

  const textFieldProps = { ...other, ...newProps };

  if (type === "select" && textFieldProps.options) {
    return renderSelect({ ...textFieldProps, mainOption, message, label }, classes);
  }
  if (type === "autocomplete") {
    return <Autocomplete {...textFieldProps} label={label} valueSize={valueSize} />;
  }
  if (type === "multiselect") {
    return (
      <Autocomplete
        mainOption={mainOption}
        {...textFieldProps}
        valueSize={valueSize}
        label={label}
        multiselect
      />
    );
  }

  let t = "text";
  if (type === "number" || type === "password") {
    t = type;
  } else if (type === "percentage" || type === "counter") {
    t = "number";
  }

  return (
    <TextField
      label={message ? renderIconInfo(props) : label}
      {...textFieldProps}
      autoComplete="just-ignore-it"
      type={t}
    />
  );
}

function renderInputComponent(props) {
  const { type = "text" } = props;

  if (isTextFieldType[type]) {
    return renderTextFieldInput(props);
  }

  if (isControlGroupType[type]) {
    const value = props.value ? props.value : Set();
    return renderControlGroup({ ...props, value });
  }

  if (isDateType[type]) {
    return renderDateFieldInput(props);
  }

  if (type === "toggle-switch") {
    const { classes, options, ...other } = props;
    const onYesElement = options?.find(
      (item) => item.get("value") === 1 || item.get("value") === true,
    );
    const onNoElement = options?.find(
      (item) => item.get("value") === 0 || item.get("value") === false,
    );
    return (
      <ToggleSwitch
        yesText={onYesElement && onYesElement.get("label")}
        noText={onNoElement && onNoElement.get("label")}
        {...other}
      />
    );
  }

  if (type === "file") {
    const { classes, ...other } = props;
    return <FileChooser {...other} />;
  }

  if (type === "filelist") {
    const { classes, ...other } = props;
    return <FileList {...other} />;
  }

  return <h3 style={{ color: "red" }}>{`${type} não é um tipo válido de input`}</h3>;
}

export default renderInputComponent;
