import React from 'react';
import { Grid, IconButton, Zoom } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Section from '../Section';
import SectionAction from './SectionAction';
import { StepContext } from '../form';

function FormSection(props) {
  const {
    index,
    title,
    onEdit,
    noTight,
    content,
    doSubmit,
    onCancel,
    isEditing,
    formState,
    resetForm,
    isSubmiting,
    disabledEdit,
    setIsEditing,
    communicating,
    defaultFormState,
    setFormDefaultState,
  } = props;
  const editFields = isEditing === index;

  const disabled = communicating || (disabledEdit && disabledEdit());

  const editBtn = (
    <Zoom in={!editFields}>
      <IconButton
        color="primary"
        disabled={disabled}
        style={{ float: 'right' }}
        onClick={() => {
          if (onEdit) {
            onEdit();
          }
          if (defaultFormState) {
            resetForm(defaultFormState);
            if (isEditing) {
              onCancel(formState, defaultFormState);
            }
          }
          if (!isEditing) {
            setFormDefaultState(formState);
          }
          setIsEditing(index);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    </Zoom>
  );

  return (
    <StepContext.Provider value={{ step: index }}>
      <Section title={title} headerRightSide={editBtn}>
        <Grid container item xs={12} md={noTight ? 12 : 10}>
          {content && content(editFields)}
        </Grid>
        <SectionAction
          doSubmit={doSubmit}
          editFields={editFields}
          isSubmiting={isSubmiting}
          communicating={communicating}
          onClose={() => {
            if (onCancel) {
              onCancel(formState, defaultFormState);
            }
            if (defaultFormState) {
              resetForm(defaultFormState);
            }
            setIsEditing(null);
          }}
        />
      </Section>
    </StepContext.Provider>
  );
}

export default FormSection;
