import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, Grid, IconButton, Paper } from '@material-ui/core';
import Header from './Header';

function styles(theme) {
  const u = theme.spacing(1);
  const smDown = theme.breakpoints.down('sm');
  const margin = u * 2;

  return {
    section: {
      borderRadius: 5,
      marginBottom: margin,
      padding: `${u * 2}px ${u * 4}px`,
    },
    dense: {
      [smDown]: {
        paddingTop: 2 * u,
        paddingDown: 2 * u,
        paddingLeft: 1.5 * u,
        paddingRight: 1.5 * u,
      },
    },
    contentPadding: {
      paddingLeft: u,
      paddingRight: u,
      [smDown]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };
}

/**
 * Painel com [Paper](https://material-ui.com/demos/paper/) como
 * plano de fundo que título e pode ter uma etiqueta (`tag`), um
 * ícone. Este componente é um
 * [Grid container](https://material-ui.com/api/grid/), logo todos os `children`
 * da `Section` devem ser `Grid item`s
 *
 * @param {object} props
 */
function Section(props) {
  const {
    largeHeaderRightSide,
    headerRightSide,
    iconBackground,
    headerGutter,
    alignContent,
    transparent,
    subsection,
    collapseIn,
    helperText,
    alignItems,
    titleColor,
    direction,
    titleIcon,
    children,
    spacing,
    justify,
    actions,
    classes,
    padded,
    status,
    title,
    style,
    dense,
    icon,
    wrap,
    xs,
    sm,
    md,
    lg,
    xl,
  } = props;

  const hasPadding = padded !== null ? padded : Boolean(title);
  const containerProps = {
    container: true,
    spacing,
    wrap,
    justify,
    direction,
    alignItems,
    alignContent,
    className: hasPadding ? classes.contentPadding : '',
  };

  const [anchor, setAnchor] = React.useState(null);

  const header = (
    <Header
      icon={icon}
      title={title}
      anchor={anchor}
      status={status}
      actions={actions}
      setAnchor={setAnchor}
      titleIcon={titleIcon}
      titleColor={titleColor}
      helperText={helperText}
      headerGutter={headerGutter}
      iconBackground={iconBackground}
      headerRightSide={headerRightSide}
      largeHeaderRightSide={largeHeaderRightSide}
    />
  );
  const body = <Grid {...containerProps}>{children}</Grid>;

  if (subsection) {
    const subProps = { item: true, xs, sm, md, lg, xl };
    return (
      <Grid style={style} {...subProps}>
        <Collapse in={collapseIn}>
          {header}
          {body}
        </Collapse>
      </Grid>
    );
  }

  const Root = transparent ? 'div' : Paper;
  const squareProps = transparent ? null : { square: true };
  return (
    <Collapse in={collapseIn}>
      <Root
        className={classNames(dense && classes.dense, classes.section)}
        style={style}
        {...squareProps}
      >
        {header}
        {body}
      </Root>
    </Collapse>
  );
}

Section.defaultProps = {
  largeHeaderRightSide: false,
  iconBackground: 'primary',
  alignContent: 'stretch',
  justify: 'flex-start',
  titleColor: 'default',
  alignItems: 'stretch',
  headerRightSide: null,
  headerGutter: null,
  transparent: false,
  subsection: false,
  status: null,
  helperText: null,
  direction: 'row',
  collapseIn: true,
  titleIcon: null,
  children: null,
  actions: null,
  wrap: 'wrap',
  plain: false,
  dense: false,
  title: null,
  padded: null,
  spacing: 1,
  icon: null,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  style: {},
  xs: 12,
};

const colValues = [false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

Section.propTypes = {
  /**
   * Título da `Section`.
   */
  title: PropTypes.string,
  /**
   * Ícone que será exibido à esquerda do título e
   * na mesma cor que ele.
   */
  titleIcon: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Cor do título.
   */
  titleColor: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'warning',
    'light',
  ]),
  /**
   * Componente de status que aparecerá ao lado do título.
   */
  status: PropTypes.shape({
    tooltip: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['success', 'warning', 'danger']).isRequired,
  }),
  /**
   * Um component a ser exibido ao lado direto do título.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      tooltip: PropTypes.string.isRequired,
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      ...IconButton.propTypes,
    }),
  ),
  /**
   * Um component a ser exibido ao lado direto do título.
   */
  headerRightSide: PropTypes.element,
  /**
   * Define uma largura maior para component exibido ao lado direto do título.
   */
  largeHeaderRightSide: PropTypes.bool,
  /**
   * Ícone a ser exibido no canto direto superior
   *  da `Section`.
   */
  icon: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Cor do plano de fundo do ícone.
   */
  iconBackground: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'warning',
  ]),
  /**
   * Um texto de ajuda exibido sob o título.
   */
  helperText: PropTypes.string,
  /**
   * Se 'true', o cabeçalho (title, tag, helperText e icon)
   * terá uma pequena margem inferior.
   */
  headerGutter: PropTypes.bool,
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/)).
   */
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/),).
   */
  alignContent: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/),).
   */
  alignItems: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]),
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/),).
   */
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/),).
   */
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  /**
   * (veja o [Grid container](https://material-ui.com/api/grid/),).
   */
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  /**
   * Se `true` a seção não terá plano de fundo
   * [Paper](https://material-ui.com/demos/paper/) nem a margem padrão.
   */
  subsection: PropTypes.bool,
  /**
   * Se `true` a seção não terá plano de fundo
   * [Paper](https://material-ui.com/demos/paper/) nem margem e nem padding.
   */
  plain: PropTypes.bool,
  /**
   * Se `true` a seção terá padding menor em telas pequenas.
   */
  dense: PropTypes.bool,
  /**
   * Se `true` o conteúdo usará o padding padrão.
   */
  padded: PropTypes.bool,
  /**
   * Se `true` a seção não terá plano de fundo
   * [Paper](https://material-ui.com/demos/paper/).
   */
  transparent: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Props xs, sm, md, lg e xl são aplicáveis somente à subsections.
   */
  xs: PropTypes.oneOf(colValues),
  sm: PropTypes.oneOf(colValues),
  md: PropTypes.oneOf(colValues),
  lg: PropTypes.oneOf(colValues),
  xl: PropTypes.oneOf(colValues),
  collapseIn: PropTypes.bool,
};

export default withStyles(styles)(Section);
