import { Box, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { useState } from "react";

import IconButton from "./IconButton";

/**
 * Essa funcao serve para criar um menu, com 3 pontinhos, que quando clicamos nele,
 * abre algumas opcoes.
 *
 * Eh um componente simples, Passando apenas um items, que eh um list
 *  com um objeto no seguinte formato:
 * {
 *  action: () => {} - recebe uma funcao que vai executar ao clicar no botao selecionado
 *  title: string com titulo da opcao
 *  icon: string com icone da opcao
 * }
 */

export function OptionsMenu(props) {
  const { items } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        style={{
          padding: "8px",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="control-panel"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {items.map((item) => (
          <MenuItem key={item.get("title")} onClick={item.get("action")}>
            <ListItemIcon>{item.get("icon")}</ListItemIcon>
            {item.get("title")}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
