// TODO remover isso
/* eslint-disable react/forbid-prop-types */
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Multifield from './Multifield';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    gridItem: {
      paddingLeft: u,
      paddingRight: u,
    },
  };
}

function GridMultifield(props) {
  const {
    xs = 12,
    sm = false,
    md = false,
    lg = false,
    xl = false,
    classes,
    style,
    ...other
  } = props;

  const sizes = { xs, sm, md, lg, xl };
  return (
    <Grid item {...sizes} className={classes.gridItem} style={style}>
      <Multifield {...other} />
    </Grid>
  );
}

GridMultifield.defaultProps = {
  xs: 12,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  noPadding: false,
};

const colValues = [false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

GridMultifield.propTypes = {
  ...Multifield.propTypes,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.object,
  noPadding: PropTypes.bool,
  xs: PropTypes.oneOf(colValues),
  sm: PropTypes.oneOf(colValues),
  md: PropTypes.oneOf(colValues),
  lg: PropTypes.oneOf(colValues),
  xl: PropTypes.oneOf(colValues),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridMultifield);
