import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Paper, withStyles, Typography, Button } from '@material-ui/core';
import { colors } from '../theme';

function styles(theme) {
  const smDown = theme.breakpoints.down('sm');
  const xsDown = theme.breakpoints.down('xs');

  return {
    paper: {
      padding: '64px 20% 128px 20%',
      [smDown]: {
        padding: '64px 15% 128px 15%',
      },
      [xsDown]: {
        padding: '64px 5% 128px 5%',
      },
    },
    flex: {
      display: 'flex',
    },
    img: {
      margin: '20px auto',
    },
    center: {
      margin: '16px auto',
    },
    title: {
      fontSize: '31px',
      [xsDown]: {
        fontSize: '25px',
      },
    },
    message: {
      fontSize: '18px',
      [xsDown]: {
        fontSize: '18px',
      },
    },
    description: {
      maxWidth: '540px',
    },
    button: {
      minWidth: '150px',
      fontWeight: 'bold',
      fontSize: '16px',
      padding: '8px 28px',
      marginTop: '52px',
      backgroundColor: colors.contrast,
      '&:hover': {
        backgroundColor: colors.contrastDark,
      },
    },
  };
}

function NoticePanel(props) {
  const {
    image,
    title,
    classes,
    message,
    actionButton,
    hideActionButton,
  } = props;

  return (
    <Paper elevation={2} className={classes.paper}>
      {image && (
        <div className={classes.flex}>
          <img src={image.src} alt={image.alt} className={classes.img} />
        </div>
      )}
      <Typography
        variant="h4"
        align="center"
        color="primary"
        className={classNames(classes.title, classes.center)}
      >
        {title}
      </Typography>
      {typeof message === 'function' ? (
        message()
      ) : (
        <Typography
          align="center"
          color="primary"
          variant="subtitle1"
          className={classNames(classes.message, classes.center)}
        >
          {message}
        </Typography>
      )}
      {!hideActionButton && (
        <div className={classes.flex}>
          {React.isValidElement(actionButton) ? (
            actionButton
          ) : (
            <Button
              color="primary"
              variant="contained"
              to={actionButton.link}
              onClick={actionButton.onClick}
              component={actionButton.link ? Link : 'button'}
              className={classNames(classes.button, classes.center)}
              style={{ backgroundColor: actionButton.color || colors.contrast }}
            >
              {actionButton.text}
            </Button>
          )}
        </div>
      )}
    </Paper>
  );
}

// thereIsButton -> !hideActionButton
// pushLocation  -> link
// srcImage      -> image.src
// altImage      -> image.alt
// link          -> actionButton.link
// onClick       -> actionButton.onClick
// buttonText    -> actionButton.text
// customButton  -> actionButton

NoticePanel.defaultProps = {
  hideActionButton: false,
  actionButton: null,
  image: null,
  title: null,
};

NoticePanel.prototypes = {
  title: PropTypes.string,
  hideActionButton: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  actionButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({
      color: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ]),
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default withStyles(styles)(NoticePanel);
