import React from 'react';
import { Grid, Button, TableRow, TableCell } from '@material-ui/core';

function DetailRow(props) {
  const {
    tuple,
    colSpan,
    padding,
    actions,
    isMobile,
    rowDetailProps,
    rowDetailComponent: RowDetailComponent,
  } = props;

  if (!actions) {
    return (
      <TableRow>
        <TableCell colSpan={colSpan} padding={padding}>
          <RowDetailComponent {...rowDetailProps} tuple={tuple} />
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow>
      <TableCell colSpan={colSpan} padding={padding}>
        <Grid container style={{ paddingBottom: 16 }}>
          <Grid item xs={isMobile ? 12 : 10}>
            <RowDetailComponent {...rowDetailProps} tuple={tuple} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 2}>
            <div style={{ float: isMobile ? 'none' : 'right' }}>
              {actions.map((action) => {
                const { icon, tooltip, onClick } = action;
                return (
                  <Grid key={tooltip} item xs={12}>
                    <Button
                      color="secondary"
                      onClick={(e) => onClick(e, tuple)}
                    >
                      <span style={{ marginRight: '8px', lineHeight: '0px' }}>
                        {icon}
                      </span>
                      {tooltip}
                    </Button>
                  </Grid>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default DetailRow;
