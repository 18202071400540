import { Box } from "@material-ui/core";
import { CheckCircle, Clear, WatchLater } from "@material-ui/icons";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { colors } from "../../theme";
import { useStyles } from "./styles";

const getIcon = (status) => {
  switch (status) {
    case "confirmed":
      return <CheckCircle fontSize="large" style={{ color: colors.success }} />;
    case "awaiting":
      return <WatchLater fontSize="large" style={{ color: colors.blueDark }} />;
    case "rejected":
      return <Clear fontSize="large" style={{ color: colors.danger }} />;
    default:
      return null;
  }
};

export function HistoryCard(props) {
  const { status, dateHour, auxiliaryText, title } = props;
  const classes = useStyles();
  return (
    <Box className={classes.history}>
      <Box className={classes.icon}>{getIcon(status)}</Box>
      <Box>
        <Box>
          <span>
            {dateHour && `${format(new Date(dateHour), "dd/MM/yyy - hh:mm")}`}
            {auxiliaryText && <b>{` • ${auxiliaryText}`}</b>}
          </span>
        </Box>
        <h2 style={{ margin: "1rem 0 0 0" }}>{title}</h2>
      </Box>
    </Box>
  );
}

HistoryCard.defaultProps = {
  dateHour: null,
  auxiliaryText: "No text",
  title: "No history or title",
  status: null,
};

HistoryCard.propTypes = {
  dateHour: PropTypes.instanceOf(Date),
  auxiliaryText: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.oneOf(["confirmed", "awaiting", "rejected"]),
};
