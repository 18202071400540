import { Children } from "react";

function tabbedProps(
  setCurrentContentDropdown,
  scrollButtons,
  nonLinear,
  stepper,
  isSmall,
  setTab,
  state,
  setMenuOpen,
) {
  const currentTab = state.get("currentTab");
  if (stepper) {
    return {
      nonLinear,
      alternativeLabel: true,
      activeStep: currentTab,
    };
  }

  let sb = scrollButtons === "off" ? "off" : null;
  if (!sb) {
    sb = isSmall ? "on" : scrollButtons;
  }
  let variant = scrollButtons === "off" ? "standard" : null;
  if (!variant) {
    variant = isSmall ? "scrollable" : "standard";
  }

  return {
    onChange: (e, i) => {
      setTab(i);
      setCurrentContentDropdown(null);
      setMenuOpen(false);
    },
    indicatorColor: "primary",
    textColor: "primary",
    scrollButtons: sb,
    value: currentTab,
    variant,
  };
}

function getChildrenArray(content, children) {
  if (content && children) {
    throw new Error('"content" ou "children" esperado, mas recebeu os dois.');
  }
  if (!content && !children) {
    throw new Error('"content" ou "children" esperado, mas não recebeu nenhum.');
  }
  if (content) {
    return content;
  }
  return Children.toArray(children);
}

export { tabbedProps, getChildrenArray };
