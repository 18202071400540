import { Box, Button, Menu, MenuItem, Paper, Step, StepButton, Tab, Tabs } from "@material-ui/core";
import MuiStepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";

const menuButtonStyles = {
  maxWidth: "300px",
  color: "rgba(96, 125, 139, 0.80)",
  padding: "6px 12px",
  overflow: "hidden",
  position: "relative",
  fontSize: "0.8125rem",
  minWidth: "160px",
  boxSizing: "border-box",
  minHeight: "48px",
  textAlign: "center",
  flexShrink: 0,
  fontFamily: "Montserrat",
  fontWeight: 500,
  lineHeight: "1.75",
  whiteSpace: "normal",
  textTransform: "uppercase",
  "@media (min-width: 960px)": {
    padding: "6px 24px",
  },

  borderRadius: 0,
};

const menuButtonActiveStyles = {
  borderBottom: "2px solid #0080ad",
  color: "#0080ad",
};

function renderStep(index, label, status, onClick) {
  const newLabel = label || `Passo ${index + 1}`;
  const onClickProps = onClick ? { onClick } : null;
  return (
    <Step key={newLabel} completed={false && status}>
      <StepButton {...onClickProps} completed={status}>
        {newLabel}
      </StepButton>
    </Step>
  );
}

function TabbedHeader(props) {
  const {
    plain,
    state,
    icons,
    setTab,
    labels,
    stepper,
    nonLinear,
    tabbedProps,
    dropdown,
    setCurrentContentDropdown,
    SetCurrentMainAction,
    menuOpen,
    setMenuOpen,
  } = props;
  const Component = stepper ? MuiStepper : Tabs;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const useStyles = makeStyles((theme) => ({
    tabsWithMenuOpen: {
      "& .MuiTabs-indicator": {
        backgroundColor: menuOpen ? "transparent" : "#0080ad",
      },
    },
  }));

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuButton = (currentLabel, index, items) => (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          ...menuButtonStyles,
          ...(menuOpen ? menuButtonActiveStyles : {}),
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        {currentLabel}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item) => (
          <MenuItem
            onClick={() => {
              setCurrentContentDropdown(item.render);
              item.mainAction ? SetCurrentMainAction(item.mainAction) : SetCurrentMainAction(null);
              if (item.onReload) {
                item.onReload();
              }
              handleClose();
            }}
          >
            {item.tab}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const body = (
    <Box styles={{ display: "flex" }}>
      <Component
        {...tabbedProps}
        variant={!stepper && "scrollable"}
        style={{
          marginBottom: 16,
        }}
        className={menuOpen ? classes.tabsWithMenuOpen : ""}
      >
        {labels.map((label, index) => {
          const currentLabel = typeof label === "function" ? label() : label;
          if (!stepper) {
            return (
              <Tab
                // eslint-disable-next-line react/no-array-index-key
                key={String(index)}
                label={currentLabel}
                icon={icons[index]}
                style={{ maxWidth: "300px", color: menuOpen && "rgba(96, 125, 139, 0.8)" }}
              />
            );
          }
          const status = state.getIn(["status", index]);
          const onClick =
            nonLinear &&
            (() => {
              setTab(index);
            });
          return renderStep(index, label, status, onClick);
        })}

        {dropdown?.map((item, index) => renderMenuButton(item.name, index, item.items))}
      </Component>
    </Box>
  );

  if (plain || !stepper) {
    return body;
  }
  return <Paper>{body}</Paper>;
}

export default TabbedHeader;
