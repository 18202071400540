// TODO remover isso

/* eslint-disable react/forbid-prop-types */
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "../../core/components/form/actions";
import qform from "../../core/components/form/queries";
import NormalInput from "../Input";
import { FormContext, StepContext } from "./index";

function makeOnChange(onInputChange, onChange) {
  if (!onChange) {
    return (event, maybeValue) => onInputChange(event, maybeValue);
  }
  return (event, maybeValue, other) => {
    onInputChange(event, maybeValue);
    onChange(event, maybeValue, other);
  };
}

function makeOnFocus(onInputFocus, onFocus, disabled) {
  if (!onFocus) {
    return () => onInputFocus(disabled);
  }
  return (event) => {
    onInputFocus(disabled);
    onFocus(event);
  };
}

function makeOnBlurFn(onInputBlur, onBlur, disabled) {
  if (!onBlur) {
    return () => onInputBlur(disabled);
  }
  return (event) => {
    onInputBlur(disabled);
    onBlur(event);
  };
}

function InputComponent(props) {
  const {
    state,
    onInit,
    onChange,
    onFocus,
    onBlur,
    onInputFocus,
    onInputBlur,
    onInputChange,
    setFieldType,
    setFieldStep,
    step,
    view,
    form,
    index,
    multifield,
    component,
    disabled,
    formDisabled,
    value, // não é passado adiante
    defaultValue, // não é passado adiante
    ...other
  } = props;
  const { type } = props;

  const { focus, error, showError, validation } = state;
  const Component = component || NormalInput;
  const onChangeFn = makeOnChange(onInputChange, onChange);
  const onFocusFn = makeOnFocus(onInputFocus, onFocus, disabled);
  const onBlurFn = makeOnBlurFn(onInputBlur, onBlur, disabled);
  React.useEffect(() => {
    onInit();
  }, []);

  React.useEffect(() => {
    setFieldStep();
  }, [step]);

  React.useEffect(() => {
    setFieldType(type);
  }, [type]);

  return (
    <Component
      {...other}
      disabled={disabled || formDisabled}
      onChange={onChangeFn}
      onFocus={onFocusFn}
      onBlur={onBlurFn}
      value={state.value}
      status={state.focus}
      focus={focus}
      error={validation || error}
      showError={showError || Boolean(validation)}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { view, form, name, index, multifield } = ownProps;
  const field = { field: name, multifield, index };
  return {
    state: qform.getFieldState(state, view, form, field),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { defaultValue, min, view, form, type, name, index, step, multifield, component } =
    ownProps;

  const onFocusProps = {
    index,
    multifield,
    field: name,
    isFocused: true,
  };
  const onBlurProps = {
    index,
    multifield,
    field: name,
    isFocused: false,
  };

  return bindActionCreators(
    {
      setFieldType: (type) =>
        actions.setFieldType(view, form, {
          field: name,
          multifield,
          index,
          type,
        }),
      onInit: () => {
        let initialValue = defaultValue;
        if (type === "counter" && !defaultValue) {
          initialValue = min;
        }
        return actions.initField(view, form, {
          defaultValue: initialValue,
          field: name,
          multifield,
          index,
          type,
          step,
        });
      },
      setFieldStep: () =>
        actions.setFieldStep(view, form, {
          field: multifield || name,
          step,
        }),
      onInputFocus: (disabled) => {
        const o = { disabled, ...onFocusProps };
        return actions.setFieldFocusAndValidate(view, form, o);
      },
      onInputBlur: (disabled) => {
        const o = { disabled, ...onBlurProps };
        return actions.setFieldFocusAndValidate(view, form, o);
      },
      onInputChange: (event, maybeValue) => {
        let value = null;

        if (component || ["date", "month", "toggle"].includes(type)) {
          value = event;
        } else if (["datetime"].includes(type)) {
          value = moment.isMoment(event) ? event.format() : event;
        } else if (
          [
            "file",
            "counter",
            "filelist",
            "checkbox",
            "chipcheck",
            "multiselect",
            "autocomplete",
            "toggle-switch",
          ].includes(type)
        ) {
          value = maybeValue;
        } else {
          const { value: targetValue } = event.target;
          value = targetValue;
        }

        return actions.setFieldState(view, form, {
          field: name,
          multifield,
          value,
          index,
        });
      },
    },
    dispatch,
  );
}

const ConnectedInputComponent = connect(mapStateToProps, mapDispatchToProps)(InputComponent);

function Input(props) {
  const context = React.useContext(FormContext);
  const stepContext = React.useContext(StepContext);

  const { view, form, formDisabled } = context;
  if (!context || !view || !form || formDisabled === undefined) {
    throw new Error(
      `
      - Um Input, GridInput e outros componentes relacionados  a formularios feitos para SuaFormatura 
       devem estar na sub-árvore de um Form;
       - O component Form precisa ter uma View relacionada;
       - Lembre-se que precisa ser no component do formulario, 
       nao no dialog ou algum component externo, a menos que seja alguma integracao, 
       como o DialogForm.`,
    );
  }
  return <ConnectedInputComponent {...props} {...context} {...stepContext} />;
}

Input.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  onChange: () => null,
  // eslint-disable-next-line react/default-props-match-prop-types
  onFocus: () => null,
  // eslint-disable-next-line react/default-props-match-prop-types
  onBlur: () => null,
};

Input.propTypes = {
  ...NormalInput.propTypes,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  index: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  multifield: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  formDisabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
