import React from 'react';
import { Checkbox, TableRow, TableCell, Tooltip } from '@material-ui/core';
import { some } from '../../../core/util';
import rowStatusColor from './row-status';
import RowAdornment from './RowAdornment';
import DetailRow from './DetailRow';

function renderValue(id, tuple) {
  const splitedValue = id.split('.');
  const value = tuple.getIn(splitedValue);
  return some(value) ? value : '-----';
}

function Row(props) {
  const {
    tuple,
    index,
    schema,
    detail,
    onClick,
    actions,
    packIcon,
    selected,
    onSelect,
    rowStatus,
    showCheckbox,
    isLargeScreen,
    rowDetailProps,
    rowDetailComponent,
    setActionMenuAnchor,
  } = props;
  const padding = isLargeScreen ? 'default' : 'checkbox';
  const style = detail ? { borderStyle: 'none' } : null;
  const clickable = Boolean(rowDetailComponent || onClick);
  const textSelect = !onClick ? null : { userSelect: 'none' };
  const showAdornment = (actions && actions.length > 0) || rowDetailComponent;
  const statusProps = rowStatus && rowStatus(tuple);
  const { color, tooltip } = statusProps || {};

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        selected={selected}
        style={{
          ...textSelect,
        }}
      >
        {statusProps && (
          <Tooltip title={tooltip || 'Status Indefinido'} placement="right">
            <TableCell
              style={rowStatusColor(color)}
              padding="none"
              width="8px"
            />
          </Tooltip>
        )}
        {showCheckbox && (
          <TableCell padding={padding} style={style}>
            <Checkbox
              color="primary"
              checked={selected}
              onChange={(e) => onSelect(e, tuple)}
            />
          </TableCell>
        )}
        {schema.map((s) => {
          const { id, format } = s;
          return (
            <TableCell
              key={id}
              padding={padding}
              onClick={onClick}
              style={
                clickable
                  ? {
                      cursor: 'pointer',
                      ...style,
                    }
                  : style
              }
              align={s.align || 'inherit'}
            >
              {format ? format(tuple, index) : renderValue(id, tuple)}
            </TableCell>
          );
        })}
        {showAdornment && (
          <RowAdornment
            setActionMenuAnchor={setActionMenuAnchor}
            rowDetailComponent={rowDetailComponent}
            packIcon={packIcon}
            padding={padding}
            onClick={onClick}
            actions={actions}
            detailed={detail}
            tuple={tuple}
          />
        )}
      </TableRow>
      {detail && rowDetailComponent && (
        <DetailRow
          rowDetailComponent={rowDetailComponent}
          rowDetailProps={rowDetailProps}
          colSpan={schema.length + 1}
          isMobile={!isLargeScreen}
          actions={actions}
          padding={padding}
          tuple={tuple}
        />
      )}
    </>
  );
}

export default Row;
