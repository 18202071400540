import React from 'react';
import classNames from 'classnames';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';

// @TODO Update: Material UI migration hotspot (vai virar hook)
function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);
  return {
    menuButton: {
      border: 'none',
      backgroundColor: 'transparent',
      color: '#333',
      cursor: 'pointer',
      transition: 'background-color 0.5s',
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: '#ddd',
        boxShadow: 'none',
      },

      '&:active': {
        boxShadow: 'none',
      },
    },
    title: {
      paddingTop: u,
      paddingBottom: u,
    },

    myTextAlign: {
      textAlign: 'right',
    },
    fontFamilyDefault: {
      fontFamily: '"Roboto", sans-serif',
    },
    container: {
      border: '1px solid #dddddd',
      minHeight: '6em',
      padding: '8px 16px',
    },
    toolbar: {
      display: 'relative',
      height: '25px',
    },

    divider: {
      margin: '8px 0px',
    },
    separator: {
      display: 'inline',
      height: '100%',
      padding: '4px',
      borderLeft: '2px solid #ddd',
    },
    toolbarBlock: {
      borderRight: '2px solid #ddd',
      paddingRight: '8px',
      marginRight: '8px',
    },
  };
}

// function handleBeforeInput(text, editorState) {
//   const { length } = text;
//   const currentContent = editorState.getCurrentContent().getPlainText();
//   const contentLenth = currentContent.length;
//   if (length + contentLenth > 2500) {
//     return true;
//   }
//   return false;
// }

// function handlePastedText(text, editorState) {
//   const { length } = text;
//   const currentContent = editorState.getCurrentContent().getPlainText();
//   const contentLenth = currentContent.length;
//   if (length + contentLenth > 2500) {
//     return true;
//   }
//   return false;
// }

function compactToolbar(classes) {
  return {
    options: ['inline', 'textAlign', 'link', 'history'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
      bold: { className: classes.menuButton },
      italic: { className: classes.menuButton },
      underline: { className: classes.menuButton },
      strikethrough: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    textAlign: {
      left: { className: classes.menuButton },
      center: { className: classes.menuButton },
      right: { className: classes.menuButton },
      justify: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    link: {
      link: { className: classes.menuButton },
      unlink: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    history: {
      undo: { className: classes.menuButton },
      redo: { className: classes.menuButton },
    },
  };
}

function fullToolbar(classes) {
  return {
    options: [
      'inline',
      'fontSize',
      'fontFamily',
      'textAlign',
      'link',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
      bold: { className: classes.menuButton },
      italic: { className: classes.menuButton },
      underline: { className: classes.menuButton },
      strikethrough: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: classNames(classes.menuButton, classes.toolbarBlock),
    },
    fontFamily: {
      options: ['Arial', 'Roboto', 'Times New Roman'],
      className: classNames(
        classes.menuButton,
        classes.toolbarBlock,
        classes.fontFamilyDefault,
      ),
    },
    textAlign: {
      left: { className: classes.menuButton },
      center: { className: classes.menuButton },
      right: { className: classes.menuButton },
      justify: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    link: {
      link: { className: classes.menuButton },
      unlink: { className: classes.menuButton },
      className: classes.toolbarBlock,
    },
    history: {
      undo: { className: classes.menuButton },
      redo: { className: classes.menuButton },
    },
  };
}

function TextEditor(props) {
  const {
    error,
    title,
    classes,
    onChange,
    onSuccess,
    showError,
    helperText,
    placeholder,
    compactMode,
    initialValue,
    mention,
  } = props;

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );

  React.useEffect(() => {
    if (onSuccess) {
      setEditorState(EditorState.createEmpty());
    }
  }, [onSuccess]);

  React.useEffect(() => {
    if (!initialValue) {
      setEditorState(EditorState.createEmpty());
    } else {
      const blocksFromHTML = htmlToDraft(initialValue);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(content));
      onChange(editorState);
    }
  }, [initialValue]);

  return (
    <div className="rdw-storybook-root">
      {title && (
        <div className={classes.title}>
          <FormLabel error={Boolean(error) && showError}>{title}</FormLabel>
        </div>
      )}
      <Editor
        mention={mention}
        editorState={editorState}
        // handleBeforeInput={text => handleBeforeInput(text, editorState)}
        // handlePastedText={text => handlePastedText(text, editorState)}
        editorStyle={{
          border: '1px solid #ddd',
          padding: '8px 16px',
          color: '#000',
          fontSize: '16px',
          fontFamily: 'roboto verdana sans-serif',
          minHeight: compactMode ? '130px' : '350px',
        }}
        toolbarStyle={{
          border: '1px solid #ddd',
        }}
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
          onChange(editorState);
        }}
        placeholder={placeholder}
        toolbar={compactMode ? compactToolbar(classes) : fullToolbar(classes)}
      />
      {((showError && error) || helperText) && (
        <FormHelperText error={showError}>
          {showError ? error : helperText}
        </FormHelperText>
      )}
    </div>
  );
}

TextEditor.defaultProps = {
  title: null,
  placeholder: '',
  onSuccess: false,
  compactMode: false,
  onChange: () => null,
};

TextEditor.protoTypes = {
  title: PropTypes.string,
  compactMode: PropTypes.bool,
  onChange: PropTypes.func,
  onSuccess: PropTypes.bool,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
};

export default withStyles(styles)(TextEditor);
