import { Table as MuiTable } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { List, Map, Set } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import ActionMenu from "../ActionMenu";
import IconButton from "../IconButton";
import Body from "./Body";
import Foot from "./Foot";
import Head from "./Head";
import { filterBySearchTerm, filterData } from "./filters";
import { mapDispatchToProps, mapStateToProps } from "./mappings";
import transformData from "./transform-data";
import { Selection, createOnToggleAllRows, getHandleClickRow, getSelection } from "./util";

function Table(props) {
  const {
    id,
    view,
    data,
    schemas,
    idField,
    actions,
    loading,
    paginate,
    onSelect,
    packIcon,
    rowStatus,
    selectable,
    onClickRow,
    renderTrail,
    noChangeHead,
    batchActions,
    stringfyTuple,
    rowDetailProps,
    paginationProps,
    rowDetailComponent,
    // State ----------------
    page,
    filters,
    sorting,
    pageSize,
    searchTerm,
    selectedRows,
    detailedTuple,
    // Actions --------------
    onSort,
    onToggleRow,
    onPageChange,
    setSelectedRows,
    setDetailedTuple,
    onChangePageSize,
    // Rest -----------------
    width,
    staticContext,
    ...rest
  } = props;
  const isLargeScreen = width === "md" || width === "lg" || width === "xl";
  const showPagination = paginate && isLargeScreen;

  const history = useHistory();
  const filteredData = React.useMemo(() => {
    const filterByFilterForm = filterData(data, filters);

    return filterBySearchTerm(filterByFilterForm, stringfyTuple, searchTerm);
  }, [searchTerm, filters, data]);

  const numberOfRows = filteredData ? filteredData.size : 0;
  const hasActions = Boolean(actions && actions.length > 0);
  const schema = isLargeScreen ? schemas.normal : schemas.mobile;
  const selection = getSelection(numberOfRows, selectedRows.size);
  const hasHeader = schema.reduce((acc, s) => acc && Boolean(s.label), true);
  const hasData = numberOfRows >= 1;
  const currentPaginationProps = paginationProps || {
    page,
    pageSize,
    onChangePageSize,
    onPageChange,
    total: numberOfRows,
  };
  const onToggleAllRows = createOnToggleAllRows({
    setSelectedRows,
    selectedRows,
    selection,
    onSelect,
    idField,
    data: filteredData,
  });
  const [anchorCtx, setAnchorCtx] = React.useState({});
  const { anchor, tuple, anchorIndex } = anchorCtx;
  const setAnchor = (anchor, tuple, anchorIndex) => {
    setAnchorCtx({ anchor, tuple, anchorIndex });
  };

  return (
    <>
      <MuiTable id={id} {...rest}>
        {hasHeader && (
          <Head
            schema={schema}
            loading={loading}
            hasData={hasData}
            sorting={sorting}
            selection={selection}
            selectable={selectable}
            noChangeHead={noChangeHead}
            batchActions={batchActions}
            numberOfRows={numberOfRows}
            selectedRows={selectedRows}
            isLargeScreen={isLargeScreen}
            hasStatus={Boolean(rowStatus)}
            showActionsColumn={hasActions}
            onSort={(id) => onSort(view, id)}
            onToggleAllRows={onToggleAllRows}
            detailsSubTable={rowDetailComponent}
          />
        )}
        <Body
          onClickRow={getHandleClickRow(actions, onClickRow, history)}
          rowDetailComponent={rowDetailComponent}
          setDetailedTuple={setDetailedTuple}
          rowDetailProps={rowDetailProps}
          setActionMenuAnchor={setAnchor}
          isLargeScreen={isLargeScreen}
          detailedTuple={detailedTuple}
          selectedRows={selectedRows}
          renderTrail={renderTrail}
          selectable={selectable}
          rowStatus={rowStatus}
          packIcon={packIcon}
          loading={loading}
          idField={idField}
          actions={actions}
          schema={schema}
          data={transformData({
            sortingKeys: schema.reduce((a, s) => ({
              ...a,
              [s.id]: s.sort,
            })),
            data: filteredData,
            showPagination,
            pageSize: currentPaginationProps.pageSize,
            sorting,
            page,
          })}
          onSelect={(e, tuple) => {
            if (onSelect) {
              onSelect(e, {
                selected: tuple,
                selection: Selection.ONE,
                previous: Set(selectedRows.valueSeq()),
              });
            }
            onToggleRow(tuple.get(idField), tuple);
          }}
        />
      </MuiTable>
      {showPagination && (
        <Foot
          onRowsPerPageChange={currentPaginationProps.onChangePageSize}
          onPageChange={currentPaginationProps.onPageChange}
          numberOfRows={currentPaginationProps.total}
          rowsPerPage={currentPaginationProps.pageSize}
          page={currentPaginationProps.page}
        />
      )}
      {hasActions && (
        <ActionMenu
          onClose={() => setAnchor(null, tuple, null)}
          onExited={() => setAnchor(null, null, null)}
          actionParams={anchorIndex}
          id={`${id}/ActionMenu`}
          actions={actions}
          anchor={anchor}
          tuple={tuple}
        />
      )}
    </>
  );
}

Table.Selection = Selection;

Table.defaultProps = {
  rowDetailComponent: null,
  rowDetailProps: null,
  stringfyTuple: null,
  noChangeHead: false,
  batchActions: null,
  selectable: false,
  renderTrail: null,
  paginationProps: null,
  idField: "codigo",
  onClickRow: null,
  rowStatus: null,
  paginate: false,
  packIcon: null,
  onSelect: null,
  loading: false,
  actions: null,
  id: "table",
  data: null,
};

Table.propTypes = {
  /**
   * Identificador da tabela
   */
  id: PropTypes.string,
  /**
   * Campo em que se encontra o id de cada linha dentro da
   * propriedade `data`.
   */
  idField: PropTypes.string,
  /**
   * Campo em que se encontra o id de cada linha.
   */
  view: PropTypes.string.isRequired,
  /**
   * Callback a ser chamado quando a linha é clicada.
   */
  onClickRow: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Se `selectable` for verdadeiro, então ao usuário selecionar mais de uma
   * linha é mostrado no head o número de linhas selecionadas. Essa prop serve
   * para prevenir que a head mude quando mais de uma linha é selecionada.
   */
  noChangeHead: PropTypes.bool,
  /**
   * Linhas que serão renderizadas ao final da tabela.
   */
  renderTrail: PropTypes.func,
  /**
   * Dados da tabela.
   */
  data: PropTypes.instanceOf(List),
  /**
   * Indicador se a o dados estão sendo carregados.
   */
  loading: PropTypes.bool,
  /**
   * rowStatus é uma função que recebe uma 'tuple' Essa 'tuple' 'e basicamente o objeto
   *  da linha relacionada) e
   * retorna um objeto que contém uma propriedade 'color' que
   * é hexadecimal de uma cor ou uma dentre as seguintes:
   * "primary", "secondary", "success", "successDark", "danger",
   * "dangerDark", "warning" e "warningDark". Também retorna
   * uma string 'tooltip' que será mostra onHover.
   */
  rowStatus: PropTypes.func,
  /**
   * Icone que aparece quando tem mais de uma action.
   */
  packIcon: PropTypes.element,
  /**
   * Ao passar essa propriedade as linhas da tabela passarão a ser do tipo
   * `Accordion` e o componente renderizado será o conteúdo do painel.
   */
  rowDetailComponent: PropTypes.oneOfType([PropTypes.instanceOf(React.Component), PropTypes.func]),
  /**
   * Props passadas para cada componente de `rowDetailComponent`
   */
  rowDetailProps: PropTypes.objectOf(PropTypes.any),
  /**
   * Formato das colunas da tabela para desktop e mobile.
   */
  schemas: PropTypes.shape({
    mobile: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        format: PropTypes.func,
        align: PropTypes.oneOf(["left", "center", "right", "justify"]),
      }),
    ).isRequired,
    normal: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        format: PropTypes.func,
        align: PropTypes.oneOf(["left", "center", "right", "justify"]),
        sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
      }),
    ).isRequired,
  }).isRequired,
  /**
   * Ações possíveis para cada linha que serão renderizadas como `MenuButton`.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-foreign-prop-types
      icon: PropTypes.element,
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      // eslint-disable-next-line react/forbid-foreign-prop-types
      ...IconButton.propTypes,
    }),
  ),
  /**
   * Ações possíveis para cada linha que serão renderizadas como `MenuButton`
   * quando a tabela tiver mais de um elemento selecionado. `selectable` deve
   * ser verdadeiro.
   */
  batchActions: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-foreign-prop-types
      icon: PropTypes.element,
      // eslint-disable-next-line react/forbid-foreign-prop-types
      ...IconButton.propTypes,
    }),
  ),
  /**
   * TODO
   */
  stringfyTuple: PropTypes.func,
  /**
   * Flag para inserir paginação na tabela.
   */
  paginate: PropTypes.bool,
  /**
   * Indica se mais de uma linha pode ser selecionada simultaneamente.
   */
  selectable: PropTypes.bool,
  /**
   * Callback chamado quando um nova linha é selecionada. `selectable` deve
   * ser verdadeiro.
   */
  onSelect: PropTypes.func,

  //
  // ----------------------------------
  //
  // Props de estado
  //
  // ----------------------------------
  //
  paginationProps: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onChangePageSize: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
  }),

  selectedRows: PropTypes.instanceOf(Map).isRequired,
  sorting: PropTypes.instanceOf(Map).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(Table));
