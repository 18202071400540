import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

/**
 * Componente utilizado para expor valores de texto
 * com um título associado.
 */
function Tag(props) {
  const { title, text, style, className, textVariant, titleVariant } = props;
  const titleVariantObj = titleVariant ? { variant: titleVariant } : null;
  const textVariantObj = textVariant ? { variant: textVariant } : null;

  return (
    <div
      className={className}
      style={{
        width: '100%',
        marginTop: 8,
        marginBottom: 8,
        ...style,
      }}
    >
      <Typography {...titleVariantObj}>{title}</Typography>
      <Typography {...textVariantObj}>{text}</Typography>
    </div>
  );
}

Tag.defaultProps = {
  text: '',
  style: null,
  className: null,
  textVariant: null,
  titleVariant: null,
};

Tag.propTypes = {
  /**
   * Título da `Tag`.
   */
  title: PropTypes.string.isRequired,
  /**
   * Valor/Conteúdo da `Tag`.
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * TODO
   */
  style: PropTypes.oneOfType([PropTypes.object]),
  /**
   * TODO
   */
  titleVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
  /**
   * TODO
   */
  textVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
  /**
   * TODO
   */
  className: PropTypes.oneOfType([PropTypes.string]),
};

export default Tag;
