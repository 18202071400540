import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Info from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
// @TODO Update: Material UI migration hotspot - vai virar hook
import withStyles from '@material-ui/core/styles/withStyles';

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);
  return {
    infoPanel: {
      marginTop: 2 * u,
      padding: '8px 16px',
      backgroundColor: '#eceff1',
      borderRadius: '4px',
      display: 'flex',
    },
    text: {
      textAlign: 'justify',
      margin: 'auto 0px',
      color: '#455a64',
    },
    icon: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 0,
      marginRight: 2 * u,
      color: '#455a64',
    },
    gutterBottom: {
      marginBottom: 2 * u,
    },
    withoutPadding: {
      padding: '0 16px',
    },
  };
}

function InfoFrame(props) {
  const {
    children,
    classes,
    text,
    gutterBottom,
    style,
    wihoutPadding,
    icon: Icon,
    iconStyle,
  } = props;
  return (
    <div
      className={classNames(
        classes.infoPanel,
        gutterBottom && classes.gutterBottom,
        wihoutPadding && classes.withoutPadding,
      )}
      style={style}
    >
      <Icon className={classes.icon} style={iconStyle} />
      <Typography className={classes.text}>{children || text}</Typography>
    </div>
  );
}

InfoFrame.defaultProps = {
  style: null,
  gutterBottom: false,
  text: '',
  wihoutPadding: false,
  icon: Info,
  iconStyle: null,
};

InfoFrame.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  // eslint-disable-next-line react/forbid-prop-types
  iconStyle: PropTypes.object,
  gutterBottom: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  wihoutPadding: PropTypes.bool,
};

export default withStyles(styles)(InfoFrame);
