import React from 'react';
import { TableHead } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import SchemaRow from './SchemaRow';
import ActionRow from './ActionRow';

function Head(props) {
  const {
    schema,
    onSort,
    sorting,
    hasData,
    loading,
    hasStatus,
    selection,
    selectable,
    batchActions,
    numberOfRows,
    selectedRows,
    noChangeHead,
    isLargeScreen,
    onToggleAllRows,
    detailsSubTable,
    showActionsColumn,
  } = props;
  const selectedSome = selectedRows.size > 0;

  return (
    <TableHead>
      {!selectedSome || noChangeHead ? (
        <SchemaRow
          schema={schema}
          onSort={onSort}
          hasData={hasData}
          loading={loading}
          sorting={sorting}
          selection={selection}
          hasStatus={hasStatus}
          selectable={selectable}
          numberOfRows={numberOfRows}
          isLargeScreen={isLargeScreen}
          onToggleAllRows={onToggleAllRows}
          detailsSubTable={detailsSubTable}
          showActionsColumn={showActionsColumn}
        />
      ) : (
        <ActionRow
          loading={loading}
          selection={selection}
          hasStatus={hasStatus}
          selectable={selectable}
          colSpan={schema.length}
          numberOfRows={numberOfRows}
          selectedRows={selectedRows}
          batchActions={batchActions}
          isLargeScreen={isLargeScreen}
          hasActions={showActionsColumn}
          onToggleAllRows={onToggleAllRows}
        />
      )}
    </TableHead>
  );
}

Head.defaultProps = {
  onToggleAllRows: null,
  selection: 'none',
};

Head.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selection: PropTypes.oneOf(['none', 'some', 'all']),
  sorting: PropTypes.instanceOf(Map).isRequired,
  showActionsColumn: PropTypes.bool.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  selectable: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  onToggleAllRows: PropTypes.func,
};

export default Head;
