import Immutable, { Map } from 'immutable';

function usualSuspects(x) {
  return x.get('nome') || x.get('descricao') || x.get('codigo') || '';
}

function getRecordValue(record, key, sortingKeys) {
  if (Array.isArray(sortingKeys)) {
    return record.getIn(sortingKeys);
  }

  const v = record.get(key);
  if (Map.isMap(v)) {
    return usualSuspects(v);
  }
  if (Immutable.isIndexed(v)) {
    return usualSuspects(v.first);
  }
  return v;
}

function sortData(data, sorting, sortingKeys) {
  const direction = sorting.get('direction');
  const key = sorting.get('key');
  if (direction !== 'asc' && direction !== 'desc') {
    return data;
  }

  const cmp = (x, y) => {
    const xv = getRecordValue(x, key, sortingKeys[key]);
    const yv = getRecordValue(y, key, sortingKeys[key]);
    const type = typeof xv;
    const rm = {};
    rm[1] = -1;
    rm[-1] = 1;
    rm[0] = 0;
    if (type === 'string') {
      const res = xv.localeCompare(yv);
      return direction !== 'desc' ? res : rm[res];
    }

    if (type === 'number') {
      const res = yv < xv ? -1 : 1;
      return direction === 'desc' ? res : rm[res];
    }
    return 0;
  };
  return data.sort(cmp);
}

function transformData(params) {
  const { data, sorting, showPagination, page, pageSize, sortingKeys } = params;

  if (!data || data.size < 1) {
    return data;
  }

  const sorted = sortData(data, sorting, sortingKeys);
  if (!showPagination) {
    return sorted;
  }

  const start = page * pageSize;
  return sorted.slice(start, start + pageSize);
}

export default transformData;
