import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
// @TODO Update: Material UI migration hotspot - vai virar hook
import { withStyles } from '@material-ui/core/styles';

// @TODO Update: Material UI migration hotspot - vai virar hook
function styles(theme) {
  const { borderRadius } = theme.shape;
  const { grey, common } = theme.palette;
  const { white } = common;
  const u = theme.spacing(1);

  return {
    textAlign: {
      textAlign: 'center',
    },
    schemaText: {
      fontWeight: 'bold',
    },
    root: {
      width: '100%',
      borderRadius,
      borderWidth: 10,
      borderColor: 'red',
    },
    table: {
      margin: 0,
      padding: 0,
      width: '100%',
      borderSpacing: 0,
      borderCollapse: 'collapse',
    },
    tableHeaderCell: {
      backgroundColor: grey[300],
      borderSpacing: 0,
      padding: u,
    },
    tableCell: {
      borderBottomWidth: 2,
      borderBottomColor: white,
      borderBottomStyle: 'solid',
      backgroundColor: grey[100],
      borderSpacing: 0,
      padding: u,
    },
  };
}

function renderRow(classes) {
  return (row, i) => (
    <tr key={String(i)}>
      {row.map((col, colIndex) => {
        const text = typeof col === 'function' ? col(colIndex) : col;
        return (
          <td key={String(colIndex)} className={classes.tableCell}>
            {typeof text !== 'object' ? (
              <Typography className={classes.textAlign}>{text}</Typography>
            ) : (
              text
            )}
          </td>
        );
      })}
    </tr>
  );
}

function SimpleTable(props) {
  const { schema, rows, classes } = props;

  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            {schema.map((s) => (
              <td key={s} className={classes.tableHeaderCell}>
                <Typography
                  className={classNames(classes.schemaText, classes.textAlign)}
                >
                  {s}
                </Typography>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>{rows.map(renderRow(classes)).toArray()}</tbody>
      </table>
    </div>
  );
}

SimpleTable.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.instanceOf(List).isRequired,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default withStyles(styles)(SimpleTable);
