import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { colors as themeColors } from '../../theme';

function styles(theme) {
  return {
    root: {
      justifyContent: 'center',
      display: 'flex',
      float: 'right',
      flexGrow: 1,
      margin: '16px 0px',
    },
    wrapper: {
      float: 'right',
      width: '180px',
    },
    loading: {
      color: themeColors.success,
      position: 'absolute',
      marginLeft: -12,
      marginTop: -12,
      left: '50%',
      top: '50%',
    },
    button: {
      width: '100%',
      fontWeight: 'bold',
      padding: '4px 32px',
      backgroundColor: theme.palette.primary.light,
    },
  };
}

function SubmitButton2(props) {
  const { loading, disabled, onClick, text, classes, ...others } = props;
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          className={classes.button}
          disabled={disabled}
          variant="contained"
          onClick={onClick}
          color="primary"
          size="large"
          {...others}
        >
          {text}
        </Button>
        {loading && disabled && (
          <CircularProgress size={24} className={classes.loading} />
        )}
      </div>
    </div>
  );
}

SubmitButton2.defaultProps = {
  disabled: false,
  loading: false,
  text: 'Salvar',
  onClick: null,
};

SubmitButton2.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default withStyles(styles)(SubmitButton2);
