import React from 'react';
import Typography from '@material-ui/core/Typography';

function Title(props) {
  const { title, subtitle, padded, style = {} } = props;
  return (
    <div style={{ marginBottom: 16, ...style }}>
      <Typography
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginTop: padded ? 24 : 0,
        }}
      >
        {title}
      </Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
    </div>
  );
}

export default Title;
