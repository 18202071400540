import { createSetObject } from '../../core/util';

const isShrinkType = createSetObject(
  'datetime-local',
  'number',
  'date',
  'time',
);

const isAutocompleteType = createSetObject('autocomplete', 'multiselect');

const isTextFieldType = createSetObject(
  'autocomplete',
  'multiselect',
  'currency',
  'password',
  'percentage',
  'counter',
  'select',
  'number',
  'phone',
  'cnpj',
  'text',
  'cgc',
  'cpf',
  'cep',
);

const isControlGroupType = createSetObject(
  'radio',
  'checkbox',
  'chipcheck',
  'toggle',
);

const isDateType = createSetObject('date', 'datetime', 'month');

export {
  isAutocompleteType,
  isControlGroupType,
  isTextFieldType,
  isShrinkType,
  isDateType,
};
