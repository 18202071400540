import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "../../core/components/tabbed/actions";
import queries from "../../core/components/tabbed/queries";
import TabbedHeader from "./TabbedHeader";
import { getChildrenArray, tabbedProps } from "./props";

function renderTab(tab) {
  if (typeof tab === "function") {
    return tab();
  }
  return tab;
}

function Tabbed(props) {
  const {
    id,
    view,
    plain,
    width,
    state,
    icons,
    dropdown,
    labels,
    setTab,
    content,
    stepper,
    onChange,
    children,
    nonLinear,
    setTabCount,
    scrollButtons,
    SetCurrentMainAction,
  } = props;
  const currentTab = state.get("currentTab");
  const isSmall = width === "xs" || width === "sm";
  const childrenArray = getChildrenArray(content, children);
  const count = childrenArray.length;

  const [currentContentDropdown, setCurrentContentDropdown] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  React.useEffect(() => {
    if (state.get("tabCount") !== count) {
      setTabCount(view, id, count);
    }
  }, [count]);
  React.useEffect(() => {
    onChange(currentTab);
  }, [currentTab]);

  if (stepper && isSmall) {
    return renderTab(childrenArray[currentTab]);
  }

  return (
    <>
      <TabbedHeader
        plain={plain}
        state={state}
        setTab={setTab}
        labels={labels}
        icons={icons}
        stepper={stepper}
        nonLinear={nonLinear}
        dropdown={dropdown}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        setCurrentContentDropdown={setCurrentContentDropdown}
        SetCurrentMainAction={SetCurrentMainAction}
        tabbedProps={tabbedProps(
          setCurrentContentDropdown,
          scrollButtons,
          nonLinear,
          stepper,
          isSmall,
          setTab,
          state,
          setMenuOpen,
        )}
      />
      {!currentContentDropdown && renderTab(childrenArray[currentTab])}
      {currentContentDropdown && renderTab(currentContentDropdown)}
    </>
  );
}

Tabbed.defaultProps = {
  onChange: () => null,
  scrollButtons: "auto",
  nonLinear: false,
  stepper: false,
  content: null,
  id: "tabbed",
  plain: false,
  icons: [],
};

Tabbed.propTypes = {
  id: PropTypes.string,
  plain: PropTypes.bool,
  stepper: PropTypes.bool,
  onChange: PropTypes.func,
  nonLinear: PropTypes.bool,
  view: PropTypes.string.isRequired,
  scrollButtons: PropTypes.oneOf(["on", "off", "auto"]),
  width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icons: PropTypes.arrayOf(PropTypes.any),
  content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.element])),
};

function mapStateToProps(state, { id = "tabbed", view }) {
  return {
    state: queries.getTabbedState(state, view, id),
  };
}

function mapDispatchToProps(dispatch, { id = "tabbed", view }) {
  return bindActionCreators(
    {
      setTab: (tab) => actions.setTab(view, id, tab),
      setTabCount: (view, id, count) => actions.setTabCount(view, id, count),
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(Tabbed));
