import React from 'react';
import classNames from 'classnames';
import { Grid, Fab, withStyles, withWidth } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import form from '../../core/components/form/actions';
import qform from '../../core/components/form/queries';
import GridInput from './GridInput';
import { FormContext } from './index';

function styles(theme) {
  const xsDown = theme.breakpoints.down('xs');
  return {
    buttonsContainer: {
      display: 'flex',
      marginLeft: '0px',
      marginTop: '16px',
      [xsDown]: {
        marginLeft: '8px',
        marginTop: '8px',
      },
    },
    button: {
      width: 20,
      height: 20,
      minHeight: 20,
      alignSelf: 'center',
      marginRight: '16px',
    },
    addButton: {
      backgroundColor: theme.palette.primary.light,
    },
  };
}

function MultifieldAction(props) {
  const { addButton, removeButton, size, classes, isMobile, index } = props;
  const { icon: addIcon, ...addActionsProps } = addButton;
  const { icon: removeIcon, ...removeActionsProps } = removeButton;

  if (isMobile) {
    return (
      <span className={classes.buttonsContainer}>
        {index === size - 1 ? (
          <Fab {...addActionsProps}>{addIcon}</Fab>
        ) : (
          <Fab {...removeActionsProps}>{removeIcon}</Fab>
        )}
      </span>
    );
  }

  return (
    <span className={classes.buttonsContainer}>
      <Fab {...addActionsProps}>{addIcon}</Fab>
      {size > 1 && <Fab {...removeActionsProps}>{removeIcon}</Fab>}
    </span>
  );
}

function calcSize(width, length) {
  if (width === 'xs') {
    if (length === 1) {
      return 1;
    }
    return 12;
  }

  if (['xl', 'lg'].includes(width)) {
    return 1;
  }
  return 2;
}

function checkFn(item, index) {
  return typeof item === 'function' ? item(index) : item;
}

function attachRenderedRow(props, width) {
  const {
    idx,
    fields,
    isLast,
    addRow,
    nOfRows,
    indexed,
    classes,
    disabled,
    rendered,
    deleteRow,
    noPadding,
    multifield,
  } = props;
  const len = fields.length;
  const colSize = Math.floor(12 / len);
  const colSpan = colSize < 1 ? 1 : colSize;
  const sizeProps = { xs: 12, md: colSpan };
  const mprops = {
    multifield,
    index: idx,
  };

  // Define as propriedade do botão de remover ou adicionar linha.
  const actionProps = {
    action: {
      isMobile: ['xs', 'sm'].includes(width),
      size: nOfRows,
      index: idx,
      addButton: {
        icon: <Add fontSize="small" />,
        onClick: addRow,
        color: 'primary',
        tooltip: 'Adicionar',
        disabled,
        className: classNames(classes.button, classes.addButton),
      },
      removeButton: {
        icon: <Remove fontSize="small" />,
        onClick: deleteRow,
        color: 'secondary',
        tooltip: 'Remover',
        disabled,
        className: classes.button,
      },
      component: MultifieldAction,
    },
  };
  const fieldsComponents = [];
  // Instância um conjunto de GridInputs com as propriedade passadas.
  for (let i = 0; i < len; i += 1) {
    const fieldProps = fields[i];
    const { label, type, name, onChange, disabled } = fieldProps;

    let newLabel = typeof label === 'function' ? label(idx) : label;
    newLabel = !indexed ? newLabel : `${newLabel} ${idx + 1}`;

    const newOnChange = onChange ? (a, b) => onChange(a, b, idx) : null;

    const props = {
      key: `${name}-${idx}`,
      ...sizeProps,
      ...fieldProps,
      ...mprops,
      noPadding,
      label: newLabel,
      type: checkFn(type, idx),
      disabled: checkFn(disabled, idx),
      onChange: newOnChange,
    };
    fieldsComponents.push(
      <GridInput
        autoFocus={isLast && i === 0 && idx !== 0}
        sloppy={nOfRows > 1}
        {...props}
      />,
    );
  }

  const gridSize = calcSize(width, len);
  rendered.push(
    <Grid
      container
      key={`${idx}-${multifield}`}
      item
      xs={gridSize === 12 ? 12 : 12 - gridSize}
    >
      {fieldsComponents}
    </Grid>,
  );
  rendered.push(
    <Grid container key={`${idx}-@action`} item xs={gridSize}>
      <MultifieldAction {...actionProps.action} classes={classes} />
    </Grid>,
  );
}

/**
 * Cria uma quantidade pré-definida de linhas de componentes
 *
 * @param {string} multifield nome do multifield
 * @param {object} fields atributos dos campos
 * @param {number} nOfRows quantidade de linhas
 * @param {function} onAdd função para adicionar uma linha
 * @param {function} deleteRow função para remover um linha
 */
function renderFields(props) {
  const {
    width,
    fields,
    addRow,
    nOfRows,
    classes,
    indexed,
    disabled,
    deleteRow,
    noPadding,
    multifield,
    onChangeSize,
  } = props;
  const rendered = [];

  for (let i = 0; i < nOfRows; i += 1) {
    const rowProps = {
      idx: i,
      fields,
      addRow: () => {
        onChangeSize(nOfRows + 1, true);
        addRow(i);
      },
      nOfRows,
      classes,
      indexed,
      disabled,
      rendered,
      noPadding,
      multifield,
      isLast: i === nOfRows - 1,
      deleteRow: () => {
        onChangeSize(nOfRows - 1, false);
        deleteRow(i);
      },
    };

    attachRenderedRow(rowProps, width);
  }

  return rendered;
}

function MultifieldComponent(props) {
  const {
    name,
    width,
    fields,
    addRow,
    nOfRows,
    classes,
    indexed,
    disabled,
    deleteRow,
    noPadding,
    onChangeSize,
  } = props;
  const renderProps = {
    width,
    fields,
    addRow,
    nOfRows,
    classes,
    indexed,
    disabled,
    deleteRow,
    noPadding,
    onChangeSize,
    multifield: name,
  };

  return <Grid container>{renderFields(renderProps)}</Grid>;
}

function mapStateToProps(state, { view, name, form = 'form' }) {
  return {
    nOfRows: qform.getMultifieldCount(state, form, view, name),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, name } = ownProps;
  const formID = ownProps.form || 'form';

  return bindActionCreators(
    {
      deleteRow: (idx) => form.removeMultifieldRow(view, formID, name, idx),
      addRow: (idx) => form.addMultifieldRow(view, formID, name, idx + 1),
    },
    dispatch,
  );
}

const ConnectedMultifield = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultifieldComponent);

function Multifield(props) {
  const context = React.useContext(FormContext);
  return <ConnectedMultifield {...props} {...context} />;
}

Multifield.defaultProps = {
  indexed: false,
  disabled: false,
  onChangeSize: () => null,
};

Multifield.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChangeSize: PropTypes.func,
  /**
   * Indica se a label dos campos devem aparecer numerados.
   */
  indexed: PropTypes.bool,
};

export default withStyles(styles)(withWidth()(Multifield));
