import React from 'react';
import { Record } from 'immutable';
import { Request } from '../../core/comm';

const StepContext = React.createContext({ step: null });

const FormContext = React.createContext({
  view: null,
  form: null,
  formDisabled: false,
});

const Submit = Record({
  method: 'post',
  request: Request(),
  buttonText: 'Salvar',
  onFail: null, // errors => action
  willSubmit: null,
  invalidateCache: null,
});

export { FormContext, StepContext, Submit };
