// TODO Update: - estudar a possibilidade de transformar esse componente em uma
//                 hook.
//               - 'useDependecy' e 'subscribeObject' provavelmente tem lógica
//                 que nunca é utilizada.
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import { mountView, unmountView } from '../core/actions';
import qsession from '../core/session/queries';

/**
 * Aplica a propriedade 'dependency' que redireciona o usuário caso
 * alguma propriedade não seja passada.
 * @param {*} props
 * @param {*} passedProps
 */
function useDependency(props, passedProps) {
  React.useEffect(() => {
    const { requestProps, dependency } = props;
    if (!requestProps || !dependency) {
      return;
    }

    const { keys, resolve } = dependency;
    if (!keys || keys.length < 1 || !resolve) {
      return;
    }

    const { history } = requestProps;
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const p = Array.isArray(k)
        ? Immutable.getIn(passedProps, k)
        : passedProps[k];
      if (p === null || p === undefined) {
        history.push(resolve);
      }
    }
  }, []);
}

/**
 * Executa todas as funções passadas em um objeto, passando
 * props como parâmetro.
 * @param {*} obj
 * @param {*} props
 */
function subscribeObject(obj, props) {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const k = keys[i];
    const fn = obj[k];
    fn(props);
  }
}

/**
 * É uma View não conectada ao Redux.
 */
function RawView(props) {
  const { component, mountView, unmountView, dependency, ...other } = props;
  const [mounted, setMounted] = React.useState(false);
  const { subscribe } = props;
  const Component = component;

  useDependency(props, other);
  React.useEffect(() => {
    mountView();
    setMounted(true);
    window.scroll(0, 0);
    if (subscribe) {
      subscribeObject(subscribe, other);
    }
    return unmountView;
  }, []);

  if (!mounted) {
    return <div />;
  }

  return <Component {...other} />;
}

RawView.defaultProps = {
  subscribe: null,
  requestProps: null,
  dependency: null,
  currentOrganizationID: null,
};

RawView.propTypes = {
  /**
   * A URI base do applet (`/bancario/contas`, por exemplo)
   */
  applet: PropTypes.string.isRequired,
  /**
   * A URI da view (`/bancario/contas/listar`, por exemplo)
   */
  view: PropTypes.string.isRequired,
  /**
   * O componente a ser renderizado
   */
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  /**
   * Um conjuto de funções a serem chamadas durante `componentDidMount`.
   * Util para fazer requisições à API.
   */
  subscribe: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.objectOf(PropTypes.func),
  ]),
  /**
   * Função para montar a `view`. Prop gerada pelo próprio componente.
   */
  unmountView: PropTypes.func.isRequired,
  /**
   * Função para desmontar a `view`. Prop gerada pelo próprio componente.
   */
  mountView: PropTypes.func.isRequired,
  /**
   * Organização atual do usuário logado.
   */
  currentOrganizationID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Propriedades da request que é passada pelo Componente `Router`
   * do `react-router-dom`.
   */
  requestProps: PropTypes.oneOfType([PropTypes.object]),
  /**
   * Função do tipo `(props) => link`.
   */
  dependency: PropTypes.shape({
    keys: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(PropTypes.string.isRequired),
      ]),
    ).isRequired,
    resolve: PropTypes.string.isRequired,
  }),
};

function attachDispatchToProps(dispatch, { applet, view }) {
  return bindActionCreators(
    {
      unmountView: () => unmountView(view),
      mountView: () => mountView(applet, view),
    },
    dispatch,
  );
}

function attachStateToProps(state) {
  return {
    currentOrganizationID: qsession.getOrganizationID(state),
  };
}

/**
 * Representa o elemento mais abstrato de uma tela. Quando uma tela
 * é montada o núcleo do sistema remove os dados de outras telas
 * do cache e, caso pertensa a um applet diferente da anterior,
 * remove os dados do applet anterior. Dados que foram passados
 * de outras `views` para a que está sendo montada são acessíveis.
 *
 * Todas as `props` passadas para `View` serão repassadas para o
 * componente a ser renderizado.
 */
const View = React.memo(
  connect(attachStateToProps, attachDispatchToProps)(RawView),
);

View.propTypes = {
  /**
   * A URI base do applet (`/bancario/contas`, por exemplo)
   */
  applet: PropTypes.string.isRequired,
  /**
   * A URI da view (`/bancario/contas/listar`, por exemplo)
   */
  view: PropTypes.string.isRequired,
  /**
   * O componente a ser renderizado
   */
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  /**
   * Um conjuto de funções a serem chamadas durante `componentDidMount`.
   * Util para fazer requisições à API.
   */
  subscribe: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.objectOf(PropTypes.func),
  ]),
};

/**
 * Essa coisa feia só está aqui para poder injetar o ID do
 * componente atual nas props dos componetes da sub-árvore.
 */
const UglyView = React.memo(
  connect(attachStateToProps)(({ currentOrganizationID, children }) =>
    children(currentOrganizationID),
  ),
);

/**
 * Retorna uma função que renderiza uma `View`. Util para ser
 * usada como a `prop` `render` de uma `Route` (react router).
 *
 * @param {object} props `props` a serem passadas para `View`.
 * @param {object} mapStateToprops função passada como primeiro
 * argumento para `connect`.
 * @param {object} mapDispatchToProps função passada como segundo
 * argumento para `connect`.
 */
View.createRenderer = (props, mapStateToProps, mapDispatchToProps) => {
  if (!mapStateToProps && !mapDispatchToProps) {
    return (requestProps) => <View {...props} requestProps={requestProps} />;
  }

  // caso o usuário passe mapStateToprops e/ou mapDispatchToProps, são
  // adicionados as mapStateToprops e mapDispatchToProps padrão de uma
  // view.
  const mdp = (dispatch, ownProps) => ({
    ...(mapDispatchToProps ? mapDispatchToProps(dispatch, ownProps) : {}),
    ...attachDispatchToProps(dispatch, ownProps),
  });

  const EnhancedView = React.memo(connect(mapStateToProps, mdp)(RawView));

  return (requestProps) => (
    <UglyView>
      {(currentOrganizationID) => (
        <EnhancedView
          {...props}
          requestProps={requestProps}
          currentOrganizationID={currentOrganizationID}
        />
      )}
    </UglyView>
  );
};

export default View;
