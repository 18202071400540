import MuiIconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @TODO Update: Material UI migration hotspot - vai virar hook
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { createSetObject } from "../core/util";
import { colors as themeColors } from "../theme";

const styles = {
  primary: { color: themeColors.primary },
  secondary: { color: themeColors.secondary },
  success: { color: themeColors.success },
  warning: { color: themeColors.warning },
  danger: { color: themeColors.danger },
  dangerDark: { color: themeColors.dangerDark },
};
// @TODO Update: estudar como remover referências diretas

const customColors = createSetObject(
  "primary",
  "secondary",
  "success",
  "warning",
  "danger",
  "dangerDark",
);

function getLinkProps(link, href) {
  if (link) {
    return { component: Link, to: link };
  }
  if (href) {
    return { component: "a", href };
  }
  return null;
}

function IconButton(props) {
  const { color = "default", tooltip, disabled, link, href, ...other } = props;
  const { children } = props;
  const linkProps = getLinkProps(link, href);
  const isCustomColor = customColors[color];

  const rootComponent = (
    <MuiIconButton
      color={isCustomColor ? "primary" : color}
      classes={isCustomColor ? { colorPrimary: styles[color] } : null}
      {...linkProps}
      {...other}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );

  if (disabled || !tooltip) {
    return rootComponent;
  }
  return <Tooltip title={tooltip}>{rootComponent}</Tooltip>;
}

IconButton.defaultProps = {
  color: "default",
  disabled: false,
  link: null,
  href: null,
  disableRipple: false,
  tooltip: null,
};

IconButton.propTypes = {
  /**
   * A cor do plano de fundo do botão.
   */
  color: PropTypes.oneOf([
    "inherit",
    "default",
    "primary",
    "secondary",
    "warning",
    "success",
    "danger",
    "dangerDark",
  ]),
  /**
   * Uma URL para uma `View`.
   */
  link: PropTypes.string,
  /**
   * Uma URL para uma página externa.
   */
  href: PropTypes.string,
  /**
   * O `title` da [Tooltip](https://material-ui.com/demos/tooltips/).
   */
  tooltip: PropTypes.string,
  /**
   * [disabled](https://material-ui.com/api/icon-button/).
   */
  disabled: PropTypes.bool,
  /**
   * [disableRipple](https://material-ui.com/api/icon-button/).
   */
  disableRipple: PropTypes.bool,
};

// @TODO Update: Material UI migration hotspot - vai virar hook
export default IconButton;
