import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    icon: {
      color: 'white',
      fontSize: 48,
    },
    iconRoot: {
      float: 'right',
      textAlign: 'center',
      borderRadius: '50%',
      paddingTop: u / 2,
      width: 7 * u,
      height: 7 * u,
    },
    defaultBg: { backgroundColor: theme.palette.grey[500] },
    primaryBg: { backgroundColor: colors.primary },
    secondaryBg: { backgroundColor: colors.secondary },
    successBg: { backgroundColor: colors.success },
    warningBg: { backgroundColor: colors.warning },
  };
}

function SectionIcon(props) {
  const { icon, iconBackground, classes } = props;
  if (!icon) {
    return null;
  }

  const Icon = icon;
  const cnames = classNames(classes.iconRoot, classes[`${iconBackground}Bg`]);
  return (
    <div className={cnames}>
      <Icon className={classes.icon} />
    </div>
  );
}

export default withStyles(styles)(SectionIcon);
