import React from 'react';
import { TableCell } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ActionButton from '../../ActionButton';

function RowAdornment(props) {
  const {
    tuple,
    padding,
    actions,
    onClick,
    detailed,
    packIcon,
    rowDetailComponent,
    setActionMenuAnchor,
  } = props;

  const style = detailed
    ? { borderStyle: 'none', maxWidth: '80px' }
    : {
        maxWidth: '80px',
      };

  if (rowDetailComponent) {
    const borderStyle = detailed ? { borderStyle: 'none' } : null;
    return (
      <TableCell
        align="center"
        padding={padding}
        onClick={(e) => onClick(e, tuple)}
        style={{ ...style, ...borderStyle, cursor: 'pointer' }}
      >
        {detailed ? <ExpandLess /> : <ExpandMore />}
      </TableCell>
    );
  }

  return (
    <TableCell align="center" style={{ width: 100 }}>
      <ActionButton
        tuple={tuple}
        style={style}
        actions={actions}
        packIcon={packIcon}
        setAnchor={setActionMenuAnchor}
      />
    </TableCell>
  );
}

export default RowAdornment;
