import React from 'react';
import classNames from 'classnames';
import { Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    titleIcon: {
      display: 'inline',
    },
    titleIconWrapper: {
      padding: u / 3,
      marginRight: u / 2,
    },
    title: { marginBottom: 2 * u },
    status: {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      display: 'inline-block',
      margin: 'auto 8px',
      filter: 'blur(2px)',
    },
    defaultTitle: { color: theme.palette.text.primary },
    primaryTitle: { color: theme.palette.primary.main },
    lightTitle: { color: theme.palette.primary.light },
    secondaryTitle: { color: theme.palette.secondary.main },
    successTitle: { color: colors.success },
    warningTitle: { color: colors.warning },
    successColor: { backgroundColor: colors.success },
    warningColor: { backgroundColor: colors.warning },
    dangerColor: { backgroundColor: colors.danger },
  };
}

function Title(props) {
  const { status, title, titleColor, titleIcon, classes } = props;
  const titleElement = title && (
    <span>
      <Typography
        variant="h6"
        color="primary"
        display="inline"
        className={classes.title}
        classes={{ colorPrimary: classes[`${titleColor}Title`] }}
      >
        {title}
      </Typography>
      {status && (
        <Tooltip title={status.tooltip}>
          <span
            className={classNames(
              classes.status,
              classes[`${status.color}Color`],
            )}
          />
        </Tooltip>
      )}
    </span>
  );

  if (!titleIcon) {
    return titleElement;
  }

  const TitleIcon = titleIcon;
  return (
    <div style={{ display: 'flex' }}>
      <span className={classes.titleIconWrapper}>
        <TitleIcon
          className={classNames(
            classes.titleIcon,
            classes[`${titleColor}Title`],
          )}
        />
      </span>
      {titleElement}
    </div>
  );
}

function SectionTitle(props) {
  const {
    hint,
    title,
    status,
    titleIcon,
    titleColor,
    helperText,
    classes,
  } = props;

  return (
    <>
      <Title
        hint={hint}
        title={title}
        status={status}
        classes={classes}
        titleIcon={titleIcon}
        titleColor={titleColor}
      />
      {helperText && (
        <Typography color="initial" gutterBottom>
          {helperText}
        </Typography>
      )}
    </>
  );
}

export default withStyles(styles)(SectionTitle);
