import React from 'react';
import { Tooltip, TableCell, TableSortLabel } from '@material-ui/core';

function Cell(props) {
  const { label, align, onSort, sorting, className, isLargeScreen } = props;
  const labelEl = <span style={{ textTransform: 'uppercase' }}>{label}</span>;

  return (
    <TableCell
      className={className}
      align={align || 'left'}
      style={
        isLargeScreen
          ? { padding: '16px 24px', fontSize: '0.75rem' }
          : { padding: '18px 12px', fontSize: '0.75rem' }
      }
    >
      {!onSort ? (
        <span style={{ textTransform: 'capitalize' }}>{label}</span>
      ) : (
        <Tooltip title="Ordenar" placement="bottom-start">
          <TableSortLabel
            onClick={onSort}
            active={sorting.active}
            direction={sorting.direction}
          >
            {labelEl}
          </TableSortLabel>
        </Tooltip>
      )}
    </TableCell>
  );
}

export default Cell;
