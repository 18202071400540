import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createSetObject } from '../../core/util';

function styles(theme) {
  const u = theme.spacing(1);
  const transparent = '#ffffff00';

  return {
    root: {
      fontSize: 12,
      margin: u / 2,
      display: 'inline',
      cursor: 'pointer',
      borderStyle: 'none',
      borderColor: transparent,
      backgroundColor: transparent,
      '&:focus': {
        outline: 0,
      },
    },
    highlight: {
      fontWeight: 900,
    },
  };
}

const isTypographyType = createSetObject('string', 'number');

function Button(props) {
  const { onClick, style, highlight, classes, children } = props;

  return (
    <button
      type="button"
      style={style}
      onClick={onClick}
      className={classes.root}
    >
      {isTypographyType[typeof children] ? (
        <Typography
          display="inline"
          className={highlight ? classes.highlight : ''}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </button>
  );
}

export default withStyles(styles)(Button);
