import React from 'react';
import 'moment/locale/pt-br';
import { InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff, Remove, Add } from '@material-ui/icons';
import IconButton from '../IconButton';
import { isShrinkType, isAutocompleteType } from './predicates';

function setShrinkLabel(InputLabelProps, shrink, type, value, variant) {
  if (shrink !== null && shrink !== undefined) {
    return { ...InputLabelProps, shrink };
  }
  if (isShrinkType[type] || variant === 'outlined' || variant === 'filled') {
    return { ...InputLabelProps, shrink: true };
  }
  if (isAutocompleteType[type] && value && value.size > 0) {
    return {
      ...InputLabelProps,
      shrink: true,
    };
  }
  return InputLabelProps;
}

function setAdornment(
  InputProps,
  type,
  onVisibilityChange,
  isPasswordVisible,
  isPassword,
  value = 0,
  onChange,
  min,
  max,
) {
  switch (type) {
    case 'counter':
      return {
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={(e) => {
                if (!min || value - 1 >= min) {
                  onChange(e, value - 1);
                }
              }}
              tooltip="Diminuir"
            >
              <Remove fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={(e) => {
                if (!max || value + 1 <= max) {
                  onChange(e, value + 1);
                }
              }}
              tooltip="Aumentar"
            >
              <Add fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      };
    case 'percentage':
      return {
        endAdornment: (
          <InputAdornment disablePointerEvents position="start">
            <Typography style={{ color: 'currentColor' }}>%</Typography>
          </InputAdornment>
        ),
        ...InputProps,
      };
    case 'currency':
      return {
        ...InputProps,
        startAdornment: (
          <InputAdornment disablePointerEvents position="start">
            <Typography style={{ color: 'currentColor' }}>R$</Typography>
          </InputAdornment>
        ),
      };
    case 'text':
    case 'password':
      if (!isPassword) {
        return InputProps;
      }
      return {
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Mostrar/Esconder a senha"
              onClick={onVisibilityChange}
              tooltip="Vizualizar"
            >
              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      };
    default:
      return InputProps;
  }
}

function setReadOnly(InputProps, readOnly) {
  if (readOnly) {
    return { ...InputProps, readOnly: true };
  }
  return InputProps;
}

function setInputProps(
  InputProps,
  type,
  readOnly,
  onVisibilityChange,
  isPasswordVisible,
  isPassword,
  value,
  onChange,
  min,
  max,
) {
  const p = setAdornment(
    InputProps,
    type,
    onVisibilityChange,
    isPasswordVisible,
    isPassword,
    value,
    onChange,
    min,
    max,
  );
  return setReadOnly(p, readOnly);
}

export { setShrinkLabel, setAdornment, setReadOnly, setInputProps };
