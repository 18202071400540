import React from 'react';
import {
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import Row from './Row';

function createHandleClickRow(params) {
  const {
    rowDetailComponent,
    setDetailedTuple,
    detailedTuple,
    onClickRow,
    tuple,
    index,
  } = params;

  if (!rowDetailComponent && !onClickRow) {
    return null;
  }
  if (!rowDetailComponent) {
    return (e) => {
      onClickRow(e, tuple, index);
    };
  }
  return (e) => {
    const id = tuple.get('codigo');

    if (detailedTuple !== id) {
      setDetailedTuple(id);
    } else {
      setDetailedTuple(null);
    }

    if (onClickRow) {
      onClickRow(e, tuple, index);
    }
  };
}

function Body(props) {
  const {
    data,
    schema,
    actions,
    idField,
    loading,
    packIcon,
    onSelect,
    rowStatus,
    selectable,
    onClickRow,
    renderTrail,
    selectedRows,
    detailedTuple,
    isLargeScreen,
    rowDetailProps,
    setDetailedTuple,
    rowDetailComponent,
    setActionMenuAnchor,
  } = props;

  if (loading) {
    const actionColumn = !actions || actions.length < 1 ? 0 : 1;
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={schema.length + actionColumn}>
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (!data || data.size < 1) {
    const actionColumn = !actions || actions.length < 1 ? 0 : 1;
    return (
      <TableBody>
        <TableRow>
          <TableCell
            style={{ padding: '14px 24px' }}
            colSpan={schema.length + actionColumn * 2}
          >
            Nenhum registro encontrado
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
  const mfn = (tuple, index) => (
    <Row
      key={tuple.get(idField) || String(index)}
      tuple={tuple}
      index={index}
      schema={schema}
      actions={actions}
      onSelect={onSelect}
      packIcon={packIcon}
      rowStatus={rowStatus}
      showCheckbox={selectable}
      isLargeScreen={isLargeScreen}
      rowDetailProps={rowDetailProps}
      rowDetailComponent={rowDetailComponent}
      detail={tuple.get('codigo') === detailedTuple}
      selected={Boolean(selectedRows.get(tuple.get(idField)))}
      setActionMenuAnchor={(anchor, tuple) =>
        setActionMenuAnchor(anchor, tuple, { index })
      }
      onClick={createHandleClickRow({
        rowDetailComponent,
        setDetailedTuple,
        detailedTuple,
        onClickRow,
        tuple,
        index,
      })}
    />
  );
  return (
    <TableBody>
      {data.map(mfn).toArray()}
      {renderTrail && renderTrail()}
    </TableBody>
  );
}

export default Body;
